/*------- Bootstrap Framework -------*/

*,
 ::after,
 ::before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
	font-size: 16px;
	height: 100%;
	direction: ltr;
}

body {
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	-webkit-font-feature-settings: "liga" 0;
	font-feature-settings: "liga" 0;
	height: 100%;
	overflow-y: scroll;
	position: relative;
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: $color;
	text-align: start;
	background-color: $background;
	font-family: 'Roboto', sans-serif;
}

@-ms-viewport {
	width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

.secondlevel ul {
	background: 0 0 !important;
	margin-inline-start: 5px !important;
	margin-inline-end: 5px !important;
}

*::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	-webkit-transition: .3s background;
	transition: .3s background;
}

::-webkit-scrollbar-thumb {
	background: #e1e6f1;
}

*:hover::-webkit-scrollbar-thumb {
	background: #adb5bd;
}

[tabindex="-1"]:focus {
	outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-block-start: 0;
	margin-block-end: .66em;
}

p {
	margin-block-start: 0;
	margin-block-end: 1rem;
}

abbr {
	&[data-bs-original-title],
	&[title] {
		text-decoration: underline;
		-webkit-text-decoration: underline dotted;
		text-decoration: underline dotted;
		cursor: help;
		border-bottom: 0;
	}
}

address {
	margin-block-end: 1rem;
	font-style: normal;
	line-height: inherit;
}

dl,
ol,
ul {
	margin-block-start: 0;
	margin-block-end: 1rem;
}

ol {
	ol,
	ul {
		margin-block-end: 0;
	}
}

ul {
	ol,
	ul {
		margin-block-end: 0;
	}
}

dt {
	font-weight: 700;
}

dd {
	margin-block-end: .5rem;
	margin-inline-start: 0;
}

dfn {
	font-style: italic;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

a {
	color: $color;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	&:hover {
		text-decoration: underline;
	}
	&:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;
		&:focus,
		&:hover {
			color: inherit;
			text-decoration: none;
		}
		&:focus {
			outline: 0;
		}
	}
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

figure {
	margin: 0 0 1rem;
}

img {
	vertical-align: middle;
	border-style: none;
	max-width: 100%;
}

svg:not(:root) {
	overflow: hidden;
}

caption {
	padding-block-start: .75rem;
	padding-block-end: .75rem;
	color: #505662;
	text-align: start;
	caption-side: bottom;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
	margin-block-end: .5rem;
}

button {
	border-radius: 0;
	&:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
	}
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

input,
optgroup,
select,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

[type=reset],
[type=submit],
button,
html [type=button] {
	-webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input {
	&[type=checkbox],
	&[type=radio] {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0;
	}
	&[type=date],
	&[type=datetime-local],
	&[type=month],
	&[type=time] {
		-webkit-appearance: listbox;
	}
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-block-end: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type=number] {
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		height: auto;
	}
}

[type=search] {
	outline-offset: -2px;
	-webkit-appearance: none;
	&::-webkit-search-cancel-button,
	&::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-block-end: .30em;
	font-weight: 500;
	line-height: 1.1;
	font-family: 'Roboto', sans-serif;
}

.h1,
h1 {
	font-size: 3.2rem;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
}

.h2,
h2 {
	font-size: 1.75rem;
	font-family: 'Roboto', sans-serif;
}

.h3,
h3 {
	font-size: 1.5rem;
	font-family: 'Roboto', sans-serif;
}

.h4,
h4 {
	font-size: 1.125rem;
	font-family: 'Roboto', sans-serif;
}

.h5,
h5 {
	font-size: 1rem;
	font-family: 'Roboto', sans-serif;
}

.h6,
h6 {
	font-size: .875rem;
	font-family: 'Roboto', sans-serif;
}

.top-0 {
	top: 0 !important;
}

.small,
small {
	font-size: 87.5%;
	font-weight: 400;
}

.mark,
mark {
	padding: .2em;
	background-color: #fcf8e3;
}

.initialism {
	font-size: 90%;
	text-transform: uppercase;
}

.blockquote {
	margin-block-end: 1rem;
	font-size: 1.171875rem;
}

.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #868e96;
	&::before {
		content: "\2014 \00A0";
	}
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.cover-image {
	background-size: cover !important;
	width: 100%;
	position: relative;
}

.img-thumbnail {
	padding: .25rem;
	background-color: $white;
	border: 1px solid $border;
	border-radius: 2px;
	max-width: 100%;
	height: auto;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-block-end: .5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 90%;
	color: #868e96;
}

.fs-10 {
	font-size: 10px !important;
}

.fs-11 {
	font-size: 11px !important;
}

.fs-12 {
	font-size: 12px !important;
}

.fs-13 {
	font-size: 13px !important;
}

.fs-14 {
	font-size: 14px !important;
}

.fs-15 {
	font-size: 15px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.fs-18 {
	font-size: 18px !important;
}

.fs-20 {
	font-size: 20px !important;
}

.fs-30 {
	font-size: 30px !important;
}

.fs-40 {
	font-size: 40px;
}

.fs-50 {
	font-size: 50px;
}

a>code {
	color: inherit;
}

kbd {
	padding: .2rem .4rem;
	font-size: 85%;
	color: $white;
	background-color: $gray-dark;
	border-radius: 2px;
	kbd {
		padding: 0;
		font-size: 100%;
		font-weight: 700;
	}
}

pre {
	display: block;
	font-size: 85%;
	color: #6e82a9;
	margin-block-start: 0;
	margin-block-end: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
	color: $gray-dark;
	padding: 1rem;
	overflow: auto;
	font-size: 85%;
	line-height: 1.45;
	background-color: #edeffb;
	border-radius: 2px;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	text-shadow: 0 1px $white;
	-webkit-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
	code {
		font-size: inherit;
		color: inherit;
		word-break: normal;
		padding: 0;
		border-radius: 0;
		border: none;
		background: 0 0;
	}
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}

.container,
.container-fluid {
	width: 100%;
	padding-inline-end: .75rem;
	padding-inline-start: .75rem;
	margin-inline-end: auto;
	margin-inline-start: auto;
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}

.row {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-inline-end: -.75rem;
	margin-inline-start: -.75rem;
}

.no-gutters {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col,
		[class*=col-] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
}
