
.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-block-end: 1rem;
	border: 1px solid transparent;
	border-radius: 2px;
	font-size: .9375rem;
	&:first-child {
		margin-block-end: 1rem !important;
	}
	&:last-child {
		margin-block-end: 0;
	}
}
.alert-heading {
	color: inherit;
}
.alert-link {
	font-weight: 500;
	&:hover {
		text-decoration: underline;
	}
	a {
		&:hover {
			text-decoration: underline;
		}
	}
}
.alert-dismissible {
	padding-inline-end: 3.90625rem;
	.close {
		position: absolute;
		top: 0;
		inset-inline-end: 0;
		padding: .75rem 1.25rem;
		color: inherit;
	}
}
hr.message-inner-separator {
	clear: both;
	margin-block-start: 10px;
	margin-block-end: 13px;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
	background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
	background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .15), rgba(0, 0, 0, 0));
}
.alert-success {
	color: #fcfdff;
	background-color: $success;
	border-color: $success;
	hr {
		border-top-color: #c5e7a4;
	}
	.alert-link {
		color: #172e00;
	}
}
.alert-info {
	color: #fcfdff;
	background-color: #00d6e6;
	border-color: #00d6e6;
	hr {
		border-top-color: #b3dcf9;
	}
	.alert-link {
		color: #193c56;
	}
}
.alert-warning {
	color: #fcfdff;
	background-color: $yellow;
	border-color: $yellow;
	hr {
		border-top-color: #fae8a4;
	}
	.alert-link {
		color: #4d3f05;
	}
}
.alert-danger {
	color: #fcfdff;
	background-color: $danger;
	border-color: $danger;
	hr {
		border-top-color: #ecacab;
	}
	.alert-link {
		color: #3f0a09;
	}
}
.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;
	hr {
		border-top-color: #ececf6;
	}
	.alert-link {
		color: #686868;
	}
}
.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
	hr {
		border-top-color: #b9bbbe;
	}
	.alert-link {
		color: #040505;
	}
}
.alert-icon {
	padding-inline-start: 3rem;
	>i {
		color: inherit !important;
		font-size: 1rem;
		position: absolute;
		top: 1rem;
		inset-inline-start: 1rem;
	}
}
.alert-avatar {
	padding-inline-start: 3.75rem;
	.avatar {
		position: absolute;
		top: .5rem;
		inset-inline-start: .75rem;
	}
}
@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}
@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}