.checkbox {
	font-weight: 400;
	position: relative;
	display: block;
	line-height: 18px;
}

.checkbox span {
	padding-inline-start: 15px;
}

.checkbox span:empty {
	float: $float-left;
}

.checkbox span:after,
.checkbox span:before {
	line-height: 18px;
	position: absolute;
}

.checkbox span:before {
	content: '';
	width: 16px;
	height: 16px;
	background-color: $border;
	border: 1px solid $border;
	top: 1px;
	inset-inline-start: 0;
}

.checkbox span:after {
	top: 1px;
	inset-inline-start: 0;
	width: 16px;
	height: 16px;
	content: '\f00c';
	font-family: FontAwesome;
	font-size: 9px;
	text-align: center;
	color: $white;
	line-height: 17px;
	display: none;
}

.checkbox input[type=checkbox] {
	opacity: 0;
	margin: 0 5px 0 0;
}

.checkbox input[type=checkbox]:checked+span:after {
	display: block;
}

.checkbox input[type=checkbox][disabled]+span,
.checkbox input[type=checkbox][disabled]+span:after,
.checkbox input[type=checkbox][disabled]+span:before {
	opacity: .75;
}

.checkbox-inline {
	display: inline-block;
}

.form-checkbox .custom-control-label::before {
	border-radius: 2px;
}

.br.form-checkbox .custom-control-label::before {
	border-radius: 50px;
}

.form-checkbox .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.form-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.form-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(5, 117, 230, .5);
}

.form-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
	background-color: rgba(5, 117, 230, .5);
}

.custom-radio .custom-control-label::before {
	border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
	background-color: rgba(5, 117, 230, .5);
}