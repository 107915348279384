.example .pagination {
	margin-block-end: 0;
}

.pagination {
	display: -ms-flexbox;
	display: flex;
	padding-inline-start: 0;
	list-style: none;
	border-radius: 2px;
}

.page-link {
	position: relative;
	display: block;
	padding: .5rem .75rem;
	margin-inline-start: -1px;
	color: $color;
	line-height: 1.25;
	background-color: $white;
	border: 1px solid $border;
	&:hover {
		z-index: 2;
		text-decoration: none;
		background-color: #eaecfb;
	}
	&:focus {
		z-index: 2;
		outline: 0;
		box-shadow: none;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}

.page-item {
	&:first-child .page-link {
		margin-inline-start: 0;
		border-start-start-radius: 2px;
		border-end-start-radius: 2px;
	}
	&:last-child .page-link {
		border-start-end-radius: 2px;
		border-end-end-radius: 2px;
	}
	&.active .page-link {
		z-index: 1;
		color: $white;
	}
	&.disabled .page-link {
		color: #ced4da;
		pointer-events: none;
		cursor: auto;
		background-color: $white;
		border-color: $border;
	}
}

.pagination-lg {
	.page-link {
		padding: .75rem 1.5rem;
		font-size: 1.125rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-start-start-radius: 2px;
			border-end-start-radius: 2px;
		}
		&:last-child .page-link {
			border-start-end-radius: 2px;
			border-end-end-radius: 2px;
		}
	}
}

.pagination-sm {
	.page-link {
		padding: .25rem .5rem;
		font-size: .875rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-start-start-radius: 2px;
			border-end-start-radius: 2px;
		}
		&:last-child .page-link {
			border-start-end-radius: 2px;
			border-end-end-radius: 2px;
		}
	}
}

ul.inbox-pagination {
	float: $float-right;
	li {
		float: $float-left;
	}
}

.pagination-simple .page-item {
	.page-link {
		background: 0 0;
		border: none;
	}
	&.active .page-link {
		color: #3d4e67;
		font-weight: 700;
	}
}

.pagination-pager {
	.page-prev {
		margin-inline-end: auto;
	}
	.page-next {
		margin-inline-start: auto;
	}
}