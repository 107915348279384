.select2-container {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;
	.select2-selection--single {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		height: 28px;
		user-select: none;
		-webkit-user-select: none;
		.select2-selection__rendered {
			display: block;
			padding-inline-start: 8px;
			padding-inline-end: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.select2-selection__clear {
			position: relative;
		}
	}
	.select2-selection--multiple {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		min-height: 32px;
		user-select: none;
		-webkit-user-select: none;
		.select2-selection__rendered {
			display: inline-block;
			overflow: hidden;
			padding-inline-start: 8px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.select2-search--inline {
		float: $float-left;
		.select2-search__field {
			box-sizing: border-box;
			border: none;
			font-size: 100%;
			margin-block-start: 5px;
			padding: 0;
			&::-webkit-search-cancel-button {
				-webkit-appearance: none;
			}
		}
	}
}
.select2-container[dir="rtl"] {
	.select2-selection--single {
		.select2-selection__rendered {
			padding-inline-end: 20px;
			padding-inline-start: 8px;
		}
	}
}
.select2-dropdown {
	background-color: white;
	border: 1px solid $border;
	border-radius: 2px;
	box-sizing: border-box;
	display: block;
	position: absolute;
	inset-inline-start: -100000px;
	width: 100%;
	z-index: 1051;
	box-shadow: 0 8px 16px 0 rgba(169, 173, 191, 0.24);
}
.select2-results {
	display: block;
}
.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}
.select2-results__option {
	padding: 6px;
	user-select: none;
	-webkit-user-select: none;
}
.select2-results__option[aria-selected] {
	cursor: pointer;
}
.select2-container--open {
	.select2-dropdown {
		inset-inline-start: 0;
	}
	.select2-dropdown--above {
		border-block-end: none;
		border-end-start-radius: 0;
		border-end-end-radius: 0;
	}
	.select2-dropdown--below {
		border-block-start: none;
		border-start-start-radius: 0;
		border-start-end-radius: 0;
	}
}
.select2-search--dropdown {
	display: block;
	padding: 4px;
	.select2-search__field {
		padding: 4px;
		width: 100%;
		box-sizing: border-box;
		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}
}
.select2-search--dropdown.select2-search--hide {
	display: none;
}
.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	inset-inline-start: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	background-color: #fff;
	filter: alpha(opacity=0);
}
.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}
.select2-container--default {
	.select2-selection--single {
		background-color: #fff;
		border: 1px solid $border;
		border-radius: 2px !important;
		.select2-selection__rendered {
			color: #444;
			line-height: 28px;
		}
		.select2-selection__clear {
			cursor: pointer;
			float: $float-right;
			font-weight: bold;
		}
		.select2-selection__placeholder {
			color: #999;
		}
		.select2-selection__arrow {
			height: 35px;
			position: absolute;
			top: 1px;
			inset-inline-end: 1px;
			width: 20px;
			b {
				border-color: #c8cde3  transparent transparent transparent;
				border-style: solid;
				border-width: 5px 4px 0 4px;
				height: 0;
				inset-inline-start: 50%;
				margin-inline-start: -12px;
				margin-block-start: -2px;
				position: absolute;
				top: 50%;
				width: 0;
			}
		}
	}
	.select2-selection--multiple {
		background-color: white;
		border: 1px solid $border;
		border-radius: 2px;
		cursor: text;
		.select2-selection__rendered {
			box-sizing: border-box;
			list-style: none;
			margin: 0;
			padding: 0 5px;
			width: 100%;
			li {
				list-style: none;
			}
		}
		.select2-selection__placeholder {
			color: #999;
			margin-block-start: 5px;
			float: $float-left;
		}
		.select2-selection__clear {
			cursor: pointer;
			float: $float-right;
			font-weight: bold;
			margin-block-start: 5px;
			margin-inline-end: 10px;
		}
		.select2-selection__choice {
			background-color: #e4e4e4;
			border: 1px solid $border;
			border-radius: 2px;
			cursor: default;
			float: $float-left;
			margin-inline-end: 5px;
			margin-block-start: 5px;
			padding: 0 5px;
		}
		.select2-selection__choice__remove {
			color: #999;
			cursor: pointer;
			display: inline-block;
			font-weight: bold;
			margin-inline-end: 2px;
			&:hover {
				color: #333;
			}
		}
	}
	.select2-search--dropdown {
		.select2-search__field {
			border: 1px solid $border;
		}
	}
	.select2-search--inline {
		.select2-search__field {
			background: transparent;
			border: none;
			outline: 0;
			box-shadow: none;
			-webkit-appearance: textfield;
		}
	}
	.select2-results {
		>.select2-results__options {
			max-height: 200px;
			overflow-y: auto;
		}
	}
	.select2-results__option[role=group] {
		padding: 0;
	}
	.select2-results__option[aria-disabled=true] {
		color: #999;
	}
	.select2-results__option[aria-selected=true] {
		background-color: $border;
		color:#000;
	}
	.select2-results__option {
		.select2-results__option {
			padding-inline-start: 1em;
			.select2-results__group {
				padding-inline-start: 0;
			}
			.select2-results__option {
				margin-inline-start: -1em;
				padding-inline-start: 2em;
				.select2-results__option {
					margin-inline-start: -2em;
					padding-inline-start: 3em;
					.select2-results__option {
						margin-inline-start: -3em;
						padding-inline-start: 4em;
						.select2-results__option {
							margin-inline-start: -4em;
							padding-inline-start: 5em;
							.select2-results__option {
								margin-inline-start: -5em;
								padding-inline-start: 6em;
							}
						}
					}
				}
			}
		}
	}
	.select2-results__group {
		cursor: default;
		display: block;
		padding: 6px;
	}
}
.select2-container--default[dir="rtl"] {
	.select2-selection--single {
		.select2-selection__clear {
			float: $float-left;
		}
		.select2-selection__arrow {
			inset-inline-start: 1px;
			inset-inline-end: auto;
		}
	}
	.select2-selection--multiple {
		.select2-selection__choice {
			float: $float-right;
			margin-inline-start: 5px;
			margin-inline-end: auto;
		}
		.select2-selection__placeholder {
			float: $float-right;
		}
		.select2-search--inline {
			float: $float-right;
		}
		.select2-selection__choice__remove {
			margin-inline-start: 2px;
			margin-inline-end: auto;
		}
	}
}
.select2-container--default.select2-container--disabled {
	.select2-selection--single {
		background-color: #eee;
		cursor: default;
		.select2-selection__clear {
			display: none;
		}
	}
	.select2-selection--multiple {
		background-color: #eee;
		cursor: default;
	}
	.select2-selection__choice__remove {
		display: none;
	}
}
.select2-container--default.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				border-color: transparent transparent $border transparent !important; 
				border-width: 0 4px 5px 4px;
			}
		}
	}
}
.select2-container--default.select2-container--focus {
	.select2-selection--multiple {
		border: solid black 1px;
		outline: 0;
	}
}
.select2-container--default.select2-container--open.select2-container--above {
	.select2-selection--single {
		border-start-start-radius: 0;
		border-start-end-radius: 0;
	}
	.select2-selection--multiple {
		border-start-start-radius: 0;
		border-start-end-radius: 0;
	}
}
.select2-container--default.select2-container--open.select2-container--below {
	.select2-selection--single {
		border-end-start-radius: 0;
		border-end-end-radius: 0;
	}
	.select2-selection--multiple {
		border-end-start-radius: 0;
		border-end-end-radius: 0;
	}
}
.select2-container--classic {
	.select2-selection--single {
		background-color: #f7f7f7;
		border: 1px solid $border;
		border-radius: 2px;
		outline: 0;
		background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
		background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
		background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
		&:focus {
			border: solid 1px $border;
		}
		.select2-selection__rendered {
			color: #444;
			line-height: 28px;
		}
		.select2-selection__clear {
			cursor: pointer;
			float: $float-right;
			font-weight: bold;
			margin-inline-end: 10px;
		}
		.select2-selection__placeholder {
			color: #999;
		}
		.select2-selection__arrow {
			background-color: #ddd;
			border: none;
			border-inline-start: 1px solid $border;
			border-start-end-radius: 2px;
			border-end-end-radius: 2px;
			height: 26px;
			position: absolute;
			top: 1px;
			inset-inline-end: 1px;
			width: 20px;
			background-image: -webkit-linear-gradient(top, #eeeeee 50%, $border 100%);
			background-image: -o-linear-gradient(top, #eeeeee 50%, $border 100%);
			background-image: linear-gradient(to bottom, #eeeeee 50%, $border 100%);
			background-repeat: repeat-x;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
			b {
				border-color: #888 transparent transparent transparent;
				border-style: solid;
				border-width: 5px 4px 0 4px;
				height: 0;
				inset-inline-start: 50%;
				margin-inline-start: -4px;
				margin-block-start: -2px;
				position: absolute;
				top: 50%;
				width: 0;
			}
		}
	}
	.select2-selection--multiple {
		background-color: white;
		border: 1px solid $border;
		border-radius: 2px;
		cursor: text;
		outline: 0;
		&:focus {
			border: solid 1px $border;
		}
		.select2-selection__rendered {
			list-style: none;
			margin: 0;
			padding: 0 5px;
		}
		.select2-selection__clear {
			display: none;
		}
		.select2-selection__choice {
			background-color: #e4e4e4;
			border: 1px solid $border;
			border-radius: 2px;
			cursor: default;
			float: $float-left;
			margin-inline-end: 5px;
			margin-block-start: 5px;
			padding: 0 5px;
		}
		.select2-selection__choice__remove {
			color: #888;
			cursor: pointer;
			display: inline-block;
			font-weight: bold;
			margin-inline-end: 2px;
			&:hover {
				color: #555;
			}
		}
	}
	.select2-search--dropdown {
		.select2-search__field {
			border: 1px solid $border;
			outline: 0;
		}
	}
	.select2-search--inline {
		.select2-search__field {
			outline: 0;
			box-shadow: none;
		}
	}
	.select2-dropdown {
		background-color: white;
		border: 1px solid transparent;
	}
	.select2-dropdown--above {
		border-block-end: none;
	}
	.select2-dropdown--below {
		border-block-start: none;
	}
	.select2-results {
		>.select2-results__options {
			max-height: 200px;
			overflow-y: auto;
		}
	}
	.select2-results__option[role=group] {
		padding: 0;
	}
	.select2-results__option[aria-disabled=true] {
		color: grey;
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: #3875d7;
		color: white;
	}
	.select2-results__group {
		cursor: default;
		display: block;
		padding: 6px;
	}
}
.select2-container--classic[dir="rtl"] {
	.select2-selection--single {
		.select2-selection__clear {
			float: $float-left;
		}
		.select2-selection__arrow {
			border: none;
			border-inline-end: 1px solid $border;
			border-radius: 0;
			border-start-start-radius: 2px;
			border-end-start-radius: 2px;
			inset-inline-start: 1px;
			inset-inline-end: auto;
		}
	}
	.select2-selection--multiple {
		.select2-selection__choice {
			float: $float-right;
			margin-inline-start: 5px;
			margin-inline-end: auto;
		}
		.select2-selection__choice__remove {
			margin-inline-start: 2px;
			margin-inline-end: auto;
		}
	}
}
.select2-container--classic.select2-container--open {
	.select2-selection--single {
		border: solid 1px $border;
		.select2-selection__arrow {
			background: transparent;
			border: none;
			b {
				border-color: transparent transparent #888 transparent !important;
				border-width: 0 4px 5px 4px;
			}
		}
	}
	.select2-selection--multiple {
		border: solid 1px $border;
	}
	.select2-dropdown {
		border-color: #5897fb;
	}
}
.select2-container--classic.select2-container--open.select2-container--above {
	.select2-selection--single {
		border-block-start: none;
		border-start-start-radius: 0;
		border-start-end-radius: 0;
		background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
		background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
		background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
	}
	.select2-selection--multiple {
		border-block-start: none;
		border-start-start-radius: 0;
		border-start-end-radius: 0;
	}
}
.select2-container--classic.select2-container--open.select2-container--below {
	.select2-selection--single {
		border-block-end: none;
		border-end-start-radius: 0;
		border-end-end-radius: 0;
		background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
		background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
		background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
		background-repeat: repeat-x;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
	}
	.select2-selection--multiple {
		border-block-end: none;
		border-end-start-radius: 0;
		border-end-end-radius: 0;
	}
}
