.row-cards> {
	.col,
	[class*=col-] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.row-deck> {
	.col,
	[class*=col-] {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: stretch;
		align-items: stretch;
	}
	.col .card,
	[class*=col-] .card {
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
	}
}

.col-text {
	max-width: 48rem;
}

.col-login {
	max-width: 24rem;
}

.gutters-0 {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col,
		[class*=col-] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
	.card {
		margin-block-end: 0;
	}
}

.gutters-xs {
	margin-inline-end: -.25rem;
	margin-inline-start: -.25rem;
	> {
		.col,
		[class*=col-] {
			padding-inline-end: .25rem;
			padding-inline-start: .25rem;
		}
	}
	.card {
		margin-block-end: .5rem;
	}
}

.gutters-sm {
	margin-inline-end: -.5rem;
	margin-inline-start: -.5rem;
	> {
		.col,
		[class*=col-] {
			padding-inline-end: .5rem;
			padding-inline-start: .5rem;
		}
	}
	.card {
		margin-block-end: 1rem;
	}
}

.gutters-lg {
	margin-inline-end: -1rem;
	margin-inline-start: -1rem;
	> {
		.col,
		[class*=col-] {
			padding-inline-end: 1rem;
			padding-inline-start: 1rem;
		}
	}
	.card {
		margin-block-end: 2rem;
	}
}

.gutters-xl {
	margin-inline-end: -1.5rem;
	margin-inline-start: -1.5rem;
	> {
		.col,
		[class*=col-] {
			padding-inline-end: 1.5rem;
			padding-inline-start: 1.5rem;
		}
	}
	.card {
		margin-block-end: 3rem;
	}
}