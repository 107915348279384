.br-7 {
	border-radius: 7px;
}

.br-ts-7 {
	border-start-start-radius: 7px !important;
}

.br-ts-10 {
	border-start-start-radius: 1rem !important;
}

.br-bs-7 {
	border-end-start-radius: 7px !important;
}

.br-bs-10 {
	border-end-start-radius: 1rem !important;
}

.br-te-7 {
	border-start-end-radius: 7px !important;
}

.br-te-10 {
	border-start-end-radius: 1rem !important;
}

.br-be-7 {
	border-end-end-radius: 7px !important;
}

.br-be-10 {
	border-end-end-radius: 1rem !important;
}

.br-ts-0 {
	border-start-start-radius: 0 !important;
}

.br-bs-0 {
	border-end-start-radius: 0 !important;
}

.br-te-0 {
	border-start-end-radius: 0 !important;
}

.br-be-0 {
	border-end-end-radius: 0 !important;
}

.border {
	border: 1px solid $border !important;
}

.br-100 {
	border-radius: 100% !important;
}

.br-4 {
	border-radius: 4px !important;
}

.br-2 {
	border-radius: 2px !important;
}

.br-ts-2 {
	border-start-start-radius: 2px !important;
}

.br-bs-2 {
	border-end-start-radius: 2px !important;
}

.br-te-2 {
	border-start-end-radius: 2px !important;
}

.br-be-2 {
	border-end-end-radius: 2px !important;
}

@media (min-width: 992px) {
	.br-md-2 {
		border-radius: 2px !important;
	}
	.br-ts-md-2 {
		border-start-start-radius: 2px !important;
	}
	.br-bs-md-2 {
		border-end-start-radius: 2px !important;
	}
	.br-te-md-2 {
		border-start-end-radius: 2px !important;
	}
	.br-be-md-2 {
		border-end-end-radius: 2px !important;
	}
	.br-md-0 {
		border-radius: 0 !important;
	}
	.br-ts-md-0 {
		border-start-start-radius: 0 !important;
	}
	.br-bs-md-0 {
		border-end-start-radius: 0 !important;
	}
	.br-te-md-0 {
		border-start-end-radius: 0 !important;
	}
	.br-be-md-0 {
		border-end-end-radius: 0 !important;
	}
}

.br-0 {
	border-radius: 0;
}

.border-block-star {
	border-block-start: 1px solid $border !important;
}

.border-end {
	border-inline-end: 1px solid $border !important;
}

.border-block-end {
	border-block-end: 1px solid $border !important;
}

.border-start {
	border-inline-start: 1px solid $border !important;
}

.border-0 {
	border: 0 !important;
}

.border-block-start-0 {
	border-block-start: 0 !important;
}

.border-inline-end-0 {
	border-inline-end: 0 !important;
}

.border-block-end-0 {
	border-block-end: 0 !important;
}

.border-inline-start-0 {
	border-inline-start: 0 !important;
}

.border-success {
	border-color: $success !important;
}

.border-info {
	border-color: #00d6e6 !important;
}

.border-warning {
	border-color: $yellow !important;
}

.border-danger {
	border-color: $danger !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: $gray-dark !important;
}

.border-white {
	border-color: $white !important;
}
.border-bottom{
	border-bottom-color: $border !important;
}

.rounded {
	border-radius: 2px !important;
}

.rounded7 {
	border-radius: 7px !important;
}

.rounded-top {
	border-start-start-radius: 2px !important;
	border-start-end-radius: 2px !important;
}

.rounded-right {
	border-start-end-radius: 2px !important;
	border-end-end-radius: 2px !important;
}

.rounded-bottom {
	border-end-end-radius: 2px !important;
	border-end-start-radius: 2px !important;
}

.rounded-left {
	border-start-start-radius: 2px !important;
	border-end-start-radius: 2px !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}