/* ================== Desktop Main Menu CSS ================== */
/* Desktop Search Bar */
/* ================== Desktop Drop Down Menu CSS ================== */
/* ================== Desktop Mega Menus CSS  ================== */
/*= Desktop Half Menu CSS =*/
/*= Desktop HTML Form Menu CSS =*/
/* ================== Desktop Extra CSS ================== */
/*==============================================================================
                              Start Mobile CSS
===============================================================================*/
/* ================== Mobile Menu Change Brake Point ================== */
/* ================== Mobile Base CSS ================== */
/* ================== Mobile Main Menu CSS ================== */
/* ================== Mobile Slide Down Links CSS ================== */
/* ================== Mobile Mega Menus CSS  ================== */
/* ================== Mobile Header CSS ================== */
/* Mobile Search Bar*/
/* Mobile Toggle Menu icon (X ICON) */
/* ================== Mobile Overlay/Drawer CSS ================== */
/* ================== Mobile Sub Menu Expander Arrows  ================== */
/*End Media Query*/
/* Extra @Media Query*/
.horizontalMenu {
	html {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	body {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	iframe {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	h1 {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	h2 {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	h3 {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	h4 {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	h5 {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	h6 {
		margin: 0;
		padding: 0;
		border: 0;
		font: inherit;
		vertical-align: baseline;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		font-family: Helvetica, sans-serif;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: antialiased;
		font-smooth: antialiased;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: subpixel-antialiased;
		font-smoothing: subpixel-antialiased;
		font-smooth: subpixel-antialiased;
	}
	.cl {
		clear: both;
	}
	img {
		border: 0 none;
		max-width: 100%;
	}
	a {
		&:focus {
			outline: none;
		}
	}
	&:before {
		content: "";
		display: table;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	color: #fff;
	font-size: 14px;
	padding: 0;
	float: $float-right;
	display: block;
	>.horizontalMenu-list {
		text-align: start;
		margin: 0 auto 0 auto;
		width: 100%;
		display: block;
		padding: 0;
		>li {
			text-align: center;
			display: block;
			padding: 0;
			margin: 0;
			float: $float-left;
			padding: 0.45rem 0;
			>a {
				display: block;
				padding: 9px 15px;
				text-decoration: none;
				position: relative;
				margin: 0 2px;
				font-weight: 500;
				border-radius: 3px;
				.fa {
					display: inline-block;
					font-size: 14px;
					line-height: inherit;
					margin-inline-end: 7px;
					vertical-align: top;
					margin-block-start: 2px;
				}
				.fa.fa-home {
					font-size: 15px;
				}
				.arrow {
					&:after {
						border-inline-start: 4px solid rgba(0, 0, 0, 0);
						border-inline-end: 4px solid rgba(0, 0, 0, 0);
						border-block-start: 4px solid;
						content: "";
						float: $float-right;
						inset-inline-end: 11px;
						height: 0;
						margin: 0 0 0 14px;
						position: absolute;
						text-align: right;
						top: 33px;
						width: 0;
					}
				}
			}
			&:last-child {
				>a {
					border-inline-end: 0px;
				}
			}
			a.menuhomeicon {
				padding-inline-start: 25px;
				padding-inline-end: 25px;
			}
			>ul.sub-menu {
				position: absolute;
				top: 51px;
				z-index: 1000;
				margin: 0px;
				padding: 5px;
				min-width: 190px;
				background-color: #fff;
				border: 1px solid $border;
				border-start-start-radius: 0px;
				border-start-end-radius: 0px;
				border-end-start-radius: 5px;
				border-end-end-radius: 5px;
				box-shadow: 0 8px 16px 0 rgba(169, 173, 191, 0.24);
				>li {
					position: relative;
					margin: 0px;
					padding: 0px;
					display: block;
					>a {
						background-image: none;
						color: #030303;
						border-inline-end: 0 none;
						text-align: start;
						display: block;
						line-height: 22px;
						padding: 8px 12px;
						text-transform: none;
						font-size: 14px;
						letter-spacing: normal;
						border-inline-end: 0px solid;
						&:hover {
							text-decoration: none;
						}
						>.fa {
							margin-inline-end: 9px;
						}
					}
					>ul.sub-menu {
						min-width: 220px;
						position: absolute;
						inset-inline-start: 100%;
						top: 0;
						margin: 0;
						padding: 0;
						list-style: none;
						background-color: #fff;
						border: 1px solid $border;
						border-start-start-radius: 0px;
						border-start-end-radius: 5px;
						border-end-start-radius: 0px;
    						border-end-end-radius: 5px;
						box-shadow: 0 8px 16px 0 rgba(169, 173, 191, 0.24);
						>li {
							position: relative;
							margin: 0px;
							padding: 0px;
							display: block;
							>a {
								background-image: none;
								color: #030303;
								border-inline-end: 0 none;
								text-align: start;
								display: block;
								line-height: 22px;
								padding: 8px 12px;
								text-transform: none;
								font-size: 13px;
								letter-spacing: normal;
								border-inline-end: 0px solid;
								&:hover {
									text-decoration: none;
								}
								>.fa {
									margin-inline-end: 9px;
								}
							}
							>ul.sub-menu {
								min-width: 220px;
								position: absolute;
								inset-inline-start: 100%;
								top: 0;
								margin: 0px;
								list-style: none;
								padding: 0px;
								background-color: #fff;
								border: 1px solid $border;
								border-start-start-radius: 0px;
								border-start-end-radius: 5px;
								border-end-start-radius: 0px;
								border-end-end-radius: 5px;
								>li {
									position: relative;
									margin: 0px;
									padding: 0px;
									display: block;
									>a {
										background-image: none;
										color: #030303;
										border-inline-end: 0 none;
										text-align: start;
										display: block;
										line-height: 22px;
										padding: 8px 12px;
										text-transform: none;
										font-size: 13px;
										letter-spacing: normal;
										border-inline-end: 0px solid;
										&:hover {
											text-decoration: none;
										}
										>.fa {
											margin-inline-end: 9px;
										}
									}
								}
							}
						}
					}
				}
			}
			>.horizontal-megamenu {
				width: 100%;
				inset-inline-start: 0px;
				position: absolute;
				top: 52px;
				color: #000;
				z-index: 1000;
				margin: 0px;
				text-align: start;
				font-size: 15px;
				.megamenu-content {
					border: 1px solid $border;
					background-color: #fff;
					padding: 14px 20px;
					border-start-start-radius: 0px;
					border-start-end-radius: 0px;
					border-end-start-radius: 5px;
					border-end-end-radius: 5px;
					box-shadow: 0 8px 16px 0 rgba(169, 173, 191, 0.24);
				}
				.title {
					padding: 5px 5px 5px 0px;
					font-size: 14px;
					color: #000;
					margin: 0px 0px 4px 0px;
					text-align: start;
					font-weight: 600;
				}
				.wsmwnutxt {
					width: 100%;
					font-size: 14px;
					text-align: justify;
					line-height: 22px;
					color: #504e70;
					margin-block-start: 10px;
				}
				.link-list {
					li {
						display: block;
						text-align: center;
						white-space: nowrap;
						text-align: start;
						padding: 5px 10px 5px 10px;
						a {
							line-height: 18px;
							border-inline-end: none;
							text-align: start;
							padding: 6px 0px;
							background: #fff;
							background-image: none;
							color: #030303;
							border-inline-end: 0 none;
							display: block;
							background-color: #fff;
							font-size: 14px;
						}
						.fa {
							font-size: 12px;
						}
					}
				}
				li {
					.fa {
						margin-inline-end: 5px;
						text-align: center;
						width: 18px;
						margin-inline-end: 5px;
						text-align: center;
						width: 18px;
					}
					a {
						&:hover {
							background: transparent;
							text-decoration: none;
						}
					}
				}
				.mrgtop {
					margin-block-start: 15px;
				}
				.show-grid {
					div {
						padding-block-end: 10px;
						padding-block-start: 10px;
						background-color: #dbdbdb;
						border: 1px solid #e7e7e7;
						color: #6a6a6a;
						margin: 2px 0px;
						font-size: 13px;
					}
				}
				.menu_form {
					width: 100%;
					display: block;
					input[type="text"] {
						width: 100%;
						border: 1px solid #e2e2e2;
						color: #000;
						font-size: 13px;
						padding: 8px 5px;
						margin-block-end: 8px;
					}
					textarea {
						width: 100%;
						border: 1px solid #e2e2e2;
						color: #000;
						font-size: 13px;
						padding: 8px 5px;
						margin-block-end: 8px;
						min-height: 122px;
					}
					input[type="submit"] {
						width: 25%;
						display: block;
						height: 32px;
						float: $float-right;
						border: none;
						margin-inline-end: 15px;
						cursor: pointer;
						background-color: #e1e1e1;
						-webkit-border-radius: 2px;
						-moz-border-radius: 2px;
						border-radius: 2px;
					}
					input[type="button"] {
						width: 25%;
						display: block;
						height: 32px;
						float: $float-right;
						border: none;
						cursor: pointer;
						background-color: #e1e1e1;
						-webkit-border-radius: 2px;
						-moz-border-radius: 2px;
						border-radius: 2px;
					}
				}
				.carousel-inner {
					.item {
						img {
							width: 100%;
						}
					}
				}
				.carousel-control-next {
					opacity: 0.8;
				}
				.carousel-control-prev {
					opacity: 0.8;
				}
				.carousel-caption {
					bottom: 0px;
					background-color: rgba(0, 0, 0, 0.7);
					font-size: 13px;
					height: 31px;
					inset-inline-start: 0;
					padding: 7px 0;
					inset-inline-end: 0;
					width: 100%;
				}
				.typography-text {
					padding: 0px 0px;
					font-size: 14px;
					ul {
						padding: 0px 0px;
						margin: 0px;
						li {
							display: block;
							padding: 2px 0px;
							line-height: 22px;
							a {
								color: #656565;
							}
						}
					}
					p {
						text-align: justify;
						line-height: 24px;
						color: #656565;
					}
				}
			}
			>.horizontal-megamenu.halfmenu {
				width: 30%;
				inset-inline-end: auto;
				inset-inline-start: auto;
			}
			>.horizontal-megamenu.halfdiv {
				width: 20%;
				inset-inline-end: auto;
				inset-inline-start: auto;
			}
		}
		>li.rightmenu {
			float: $float-right;
			float: $float-right;
			zoom: 1;
			a {
				padding: 0px 30px 0px 20px;
				border-inline-end: none;
				.fa {
					font-size: 15px;
				}
			}
			&:before {
				content: "";
				display: table;
			}
			&:after {
				clear: both;
			}
			>.topmenusearch {
				float: $float-right;
				width: 210px;
				height: 39px;
				position: relative;
				margin: 16px 0px 0px 0px;
				.searchicon {
					-webkit-transition: all 0.7s ease 0s;
					-moz-transition: all 0.7s ease 0s;
					-o-transition: all 0.7s ease 0s;
					transition: all 0.7s ease 0s;
				}
				input {
					width: 100%;
					position: relative;
					float: $float-right;
					top: 0;
					inset-inline-end: 0;
					bottom: 0;
					width: 100%;
					border: 0;
					padding: 0;
					margin: 0;
					text-indent: 15px;
					height: 39px;
					z-index: 2;
					outline: none;
					color: #333;
					background-color: #efefef;
					-webkit-transition: all 0.7s ease 0s;
					-moz-transition: all 0.7s ease 0s;
					-o-transition: all 0.7s ease 0s;
					transition: all 0.7s ease 0s;
					font-size: 12px;
					&::placeholder {
						color: #6e82a9;
					}
					&:focus {
						color: #333;
						width: 230px;
					}
					&:focus~.btnstyle {
						.fa {
							color: #000;
							opacity: 1;
						}
					}
					&:focus~.searchicon {
						opacity: 1;
						z-index: 3;
						color: #FFFFFF;
					}
				}
				.btnstyle {
					top: 0px;
					position: absolute;
					inset-inline-end: 0;
					bottom: 0;
					width: 40px;
					line-height: 30px;
					z-index: 1;
					cursor: pointer;
					opacity: 0.3;
					color: #333;
					z-index: 1000;
					background-color: transparent;
					border: solid 0px;
					-webkit-transition: all 0.7s ease 0s;
					-moz-transition: all 0.7s ease 0s;
					-o-transition: all 0.7s ease 0s;
					transition: all 0.7s ease 0s;
					.fa {
						line-height: 37px;
						margin: 0;
						padding: 0;
						text-align: center;
					}
					&:hover {
						.fa {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	.horizontalMenu-click {
		display: none;
	}
	.horizontalMenu-click02 {
		display: none;
	}
	ul {
		li {
			a.btn {
				font-size: 14px !important;
			}
		}
	}
}
object {
	border: 0 none;
	max-width: 100%;
}
embed {
	border: 0 none;
	max-width: 100%;
}
video {
	border: 0 none;
	max-width: 100%;
}
.horizontalMenucontainer {
	background-size: cover;
	overflow: hidden;
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
}
.horizontal-main {
	width: 100%;
	position: relative;
	background: #fff;
}
.horizontal-mainwrapper {
	margin: 0 auto;
}
.desktoplogo {
	padding: 0.5rem 0;
	margin: 0;
	float: $float-left;
	img {
		width: 100%;
	}
}
.horizontalMenu-list {
	>li.rightmenu {
		&:after {
			content: "";
			display: table;
		}
	}
	.sub-menu {
		.sub-menu {
			&:before {
				display: none;
			}
			&:after {
				display: none;
			}
		}
	}
}
.horizontal-header {
	display: none;
}
.horizontal-overlapbg {
	opacity: 0;
	visibility: hidden;
}
.hometext {
	display: none;
}
@media (min-width:1024px) {
	.horizontalMenu {
		>.horizontalMenu-list {
			>li {
				>.horizontal-megamenu {
					.link-list {
						&:first-child {
							border: 0;
						}
						border-inline-start: 1px solid $border;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 991px) {
	body.active {
		overflow: hidden;
	}
	.desktoplogo {
		display: none;
	}
	.horizontal-main {
		height: 0;
		padding: 0;
	}
	.horizontalMenucontainer {
		background-attachment: local;
		background-position: 33% 0%;
		-webkit-transition: all 0.25s ease-in-out;
		-moz-transition: all 0.25s ease-in-out;
		-o-transition: all 0.25s ease-in-out;
		-ms-transition: all 0.25s ease-in-out;
		transition: all 0.25s ease-in-out;
	}
	.horizontalMenu {
		width: 100%;
		background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
		inset-inline-start: 0;
		overflow-y: hidden;
		padding: 0;
		top: 0;
		position: fixed;
		visibility: hidden;
		margin: 0px;
		>.horizontalMenu-list {
			height: auto;
			min-height: 100%;
			width: 240px;
			background: #fff;
			padding-block-end: 0;
			margin-inline-start: -240px;
			display: block;
			text-align: center;
			>li {
				width: 100%;
				display: block;
				float: none;
				border-inline-end: none;
				background-color: transparent;
				position: relative;
				white-space: inherit;
				clear: right;
				padding: 0;
				span {
					a {
						width: 85%;
						margin: 0 auto;
					}
				}
				>a {
					padding: 8px 32px 8px 17px;
					font-size: 14px;
					text-align: start;
					border-inline-end: solid 0px;
					color: #030303;
					line-height: 25px;
					border-block-end: 1px solid;
					border-block-end-color: $border;
					position: static;
					margin: 0;
					border-radius: 0px;
					span.fa.fa-caret-down {
						display: none;
					}
					>.fa {
						font-size: 16px;
						color: #bfbfbf;
					}
					.arrow {
						&:after {
							display: none;
						}
					}
					>.hometext {
						display: inline-block;
					}
				}
				a.menuhomeicon {
					padding-inline-start: 17px;
					padding-inline-end: 17px;
					border-block-start: solid 1px $border;
				}
				&:hover {
					>a {
						text-decoration: none;
					}
				}
				>ul.sub-menu {
					display: none;
					position: relative;
					top: 0px;
					background-color: #fff;
					border: none;
					padding: 0px;
					opacity: 1;
					visibility: visible;
					-webkit-transform: none;
					-moz-transform: none;
					-ms-transform: none;
					-o-transform: none;
					transform: none;
					-webkit-transition: inherit;
					-moz-transition: inherit;
					transition: inherit;
					-webkit-transform-style: flat;
					box-shadow: none;
					li {
						&:last-child {
							border-block-end: 1px solid $border;
						}
						&:hover {
							>a {
								background-color: #f6f7fb;
								color: #030303;
							}
						}
					}
					>li {
						>a {
							line-height: 20px;
							font-size: 13px;
							padding-block-start: 10px;
							padding-inline-end: 0px;
							padding-block-end: 10px;
    							padding-inline-start: 16px;
							color: #383838;
							>.fa {
								display: none;
							}
							&:hover {
								background-color: #f6f7fb;
								color: #030303;
								text-decoration: none;
							}
						}
						span+a {
							padding-inline-end: 30px;
						}
						>ul.sub-menu {
							width: 100%;
							position: static;
							inset-inline-start: 100%;
							top: 0;
							display: none;
							margin: 0px;
							padding: 0px;
							border: solid 0px;
							transform: none;
							opacity: 1;
							visibility: visible;
							-webkit-transform: none;
							-moz-transform: none;
							-ms-transform: none;
							-o-transform: none;
							transform: none;
							-webkit-transition: inherit;
							-moz-transition: inherit;
							transition: inherit;
							-webkit-transform-style: flat;
							>li {
								margin: 0px 0px 0px 0px;
								padding: 0px;
								position: relative;
								>a {
									line-height: 20px;
									font-size: 13px;
									padding-block-start: 10px;
									padding-inline-end: 0px;
									padding-block-end: 10px;
									padding-inline-start: 26px;
									color: #383838;
									>.fa {
										display: none;
									}
									&:hover {
										background-color: #e7e7e7;
										color: #030303;
										text-decoration: none;
									}
								}
								span+a {
									padding-inline-end: 30px;
								}
								>a.active {
									color: #000;
								}
								&:hover {
									>a {
										color: #000;
									}
								}
								>ul.sub-menu {
									width: 100%;
									position: static;
									inset-inline-start: 100%;
									top: 0;
									display: none;
									margin: 0px;
									padding: 0px;
									border: solid 0px;
									transform: none;
									opacity: 1;
									visibility: visible;
									-webkit-transform: none;
									-moz-transform: none;
									-ms-transform: none;
									-o-transform: none;
									transform: none;
									-webkit-transition: inherit;
									-moz-transition: inherit;
									transition: inherit;
									-webkit-transform-style: flat;
									>li {
										margin: 0px 0px 0px 0px;
										>a {
											line-height: 20px;
											font-size: 13px;
											padding-block-start: 10px;
											padding-inline-end: 0px;
											padding-block-end: 10px;
											padding-inline-start: 34px;
											color: #383838;
											>.fa {
												display: none;
											}
											&:hover {
												background-color: #e7e7e7;
												color: #030303;
												text-decoration: none;
											}
										}
										span+a {
											padding-inline-end: 30px;
										}
										>a.active {
											color: #000;
										}
									}
								}
							}
						}
						.horizontalMenu-click02 {
							height: 41px;
							position: absolute;
							top: 0;
							inset-inline-end: 0;
							display: block;
							cursor: pointer;
							width: 100%;
							>i {
								display: block;
								height: 23px;
								width: 25px;
								margin: 8px 6px 8px 6px;
								background-size: 25px;
								font-size: 21px;
								color: rgba(0, 0, 0, 0.25);
								float: $float-right;
							}
							>i.horizontalMenu-rotate {
								-webkit-transform: rotate(180deg);
								-moz-transform: rotate(180deg);
								-ms-transform: rotate(180deg);
								-o-transform: rotate(180deg);
								transform: rotate(180deg);
							}
						}
					}
				}
				>.horizontal-megamenu {
					.container {
						padding: 0;
					}
					color: #030303;
					display: none;
					position: relative;
					top: 0px;
					padding: 0px 0px;
					border: solid 0px;
					transform: none;
					opacity: 1;
					visibility: visible;
					-webkit-transform: none;
					-moz-transform: none;
					-ms-transform: none;
					-o-transform: none;
					transform: none;
					-webkit-transition: inherit;
					-moz-transition: inherit;
					transition: inherit;
					border-block-end: 1px solid rgba(0, 0, 0, 0.13);
					-webkit-transform-style: flat;
					.title {
						color: #030303;
						font-size: 15px;
						padding: 10px 8px 10px 0px;
					}
					>ul {
						width: 100%;
						margin: 0px;
						padding: 0px;
						font-size: 13px;
						>li {
							>a {
								padding: 9px 14px;
								line-height: normal;
								font-size: 13px;
								background-color: #e7e7e7;
								color: #030303;
								&:hover {
									background-color: #000000;
								}
							}
						}
					}
					ul {
						li.title {
							line-height: 26px;
							color: #030303;
							margin: 0px;
							font-size: 15px;
							padding: 7px 0px;
							background-color: transparent;
						}
					}
					.menu_form {
						padding: 5px 0px 62px 0px;
						input[type="button"] {
							width: 46%;
						}
						input[type="submit"] {
							width: 46%;
						}
						textarea {
							min-height: 100px;
						}
					}
					.show-grid {
						div {
							margin: 0px;
						}
					}
				}
				>.horizontal-megamenu.halfmenu {
					width: 100%;
					margin: 0px;
					padding: 5px 0px 10px 0px;
				}
				>.horizontal-megamenu.halfdiv {
					width: 100%;
				}
				>.horizontalMenu-click {
					height: 49px;
					position: absolute;
					top: 0;
					inset-inline-end: 0;
					display: block;
					cursor: pointer;
					width: 100%;
					>i {
						display: block;
						height: 23px;
						width: 25px;
						margin-block-start: 14px;
						margin-inline-end: 10px;
						background-size: 25px;
						font-size: 21px;
						color: $border;
						float: $float-right;
					}
				}
				.horizontalMenu-click.ws-activearrow {
					>i {
						transform: rotate(180deg);
					}
				}
			}
			>li.rightmenu {
				>.topmenusearch {
					width: 86%;
					margin: 7% 7%;
					padding: 0px;
					input {
						border-radius: 50px;
						&:focus {
							width: 100%;
						}
					}
				}
			}
		}
	}
	.horizontal-header {
		width: 100%;
		display: block;
		position: relative;
		top: 0;
		inset-inline-start: 0;
		z-index: 1;
		height: 54px;
		background-color: #fff;
		text-align: center;
		-webkit-transition: all 0.25s ease-in-out;
		-moz-transition: all 0.25s ease-in-out;
		-o-transition: all 0.25s ease-in-out;
		-ms-transition: all 0.25s ease-in-out;
		transition: all 0.25s ease-in-out;
		box-shadow: 2px 4px 29px -4px $black-1;
		.callusbtn {
			i {
				color: #6e82a9;
			}
		}
		.smllogo {
			display: inline-block;
			margin-block-start: 5px;
		}
	}
	.active {
		.horizontal-header {
			-webkit-transition: all 0.25s ease-in-out;
			-moz-transition: all 0.25s ease-in-out;
			-o-transition: all 0.25s ease-in-out;
			-ms-transition: all 0.25s ease-in-out;
			transition: all 0.25s ease-in-out;
		}
		.animated-arrow {
			span {
				&:after {
					width: 23px;
					top: -3px;
					transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-ms-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
				}
				background-color: transparent;
				&:before {
					top: -3px;
					transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					-webkit-transform: rotate(45deg);
					bottom: 0px;
				}
			}
		}
		.animated-arrow.active {
			span {
				&:after {
					top: -3px;
				}
			}
		}
		.horizontalMenu {
			.horizontal-overlapbg {
				opacity: 1;
				visibility: visible;
				-webkit-transition: opacity 1.5s ease-in-out;
				-moz-transition: opacity 1.5s ease-in-out;
				-ms-transition: opacity 1.5s ease-in-out;
				-o-transition: opacity 1.5s ease-in-out;
			}
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			visibility: visible;
			z-index: 1000;
			top: 0;
			height: 100% !important;
			>.horizontalMenu-list {
				-webkit-transition: all 0.25s ease-in-out;
				-moz-transition: all 0.25s ease-in-out;
				-o-transition: all 0.25s ease-in-out;
				-ms-transition: all 0.25s ease-in-out;
				transition: all 0.25s ease-in-out;
				margin-inline-start: 0;
			}
		}
		.horizontalMenucontainer {
			margin-inline-start: 240px;
			-webkit-transition: all 0.25s ease-in-out;
			-moz-transition: all 0.25s ease-in-out;
			-o-transition: all 0.25s ease-in-out;
			-ms-transition: all 0.25s ease-in-out;
			transition: all 0.25s ease-in-out;
		}
	}
	.callusbtn {
		color: #6e82a9;
		font-size: 21px;
		position: absolute;
		inset-inline-end: 5px;
		top: 0px;
		transition: all 0.4s ease-in-out 0s;
		z-index: 102;
		padding: 11px 14px;
		.fa {
			vertical-align: top;
			margin-block-start: 4px;
		}
		&:hover {
			.fa {
				color: #6e82a9;
			}
		}
	}
	.animated-arrow {
		position: absolute;
		inset-inline-start: 0;
		top: 0;
		z-index: 102;
		-webkit-transition: all 0.4s ease-in-out;
		-moz-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		-ms-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
		cursor: pointer;
		padding: 16px 35px 16px 0px;
		margin: 11px 0 0 15px;
		span {
			cursor: pointer;
			height: 2px;
			width: 17px;
			background: #6e82a9;
			position: absolute;
			display: block;
			content: '';
			transition: all 500ms ease-in-out;
			&:before {
				cursor: pointer;
				height: 2px;
				width: 17px;
				background: #6e82a9;
				position: absolute;
				display: block;
				content: '';
				top: -7px;
				width: 23px;
				transition: all 500ms ease-in-out;
			}
			&:after {
				cursor: pointer;
				height: 2px;
				width: 17px;
				background: #6e82a9;
				position: absolute;
				display: block;
				content: '';
				bottom: -7px;
				width: 17px;
				transition: all 500ms ease-in-out;
			}
		}
	}
	.horizontal-overlapbg {
		inset-inline-end: 0;
		width: calc(100% - 240px);
		height: 100vh;
		min-height: 100%;
		position: fixed;
		top: 0;
		opacity: 0;
		visibility: hidden;
		background-color: rgba(0, 0, 0, 0.45);
		cursor: pointer;
	}
	.horizontalMenu-list {
		.fe {
			display: none;
		}
	}
}
@media only screen and (min-width: 992px) and (max-width:1162px) {
	.header-style1 {
		.desktoplogo {
			float: inherit;
			margin: 0 auto;
			text-align: center;
			border-block-end: 1px solid rgba(255,255,255,0.1);
			img {
				width: 120px;
			}
		}
		.is-sticky {
			.desktoplogo-1 {
				img {
					display: none;
				}
			}
		}
		.horizontalMenu {
			float: inherit;
		}
	}
	.horizontalMenu {
		>.horizontalMenu-list {
			>li {
				>a {
					>.arrow {
						display: none;
					}
					padding-inline-start: 12px;
					padding-inline-end: 12px;
				}
				a.menuhomeicon {
					padding-inline-start: 22px;
					padding-inline-end: 22px;
				}
				>.horizontal-megamenu {
					.megamenu-content {
						box-shadow: none;
					}
				}
			}
		}
	}
}
@media (max-width:992px) {
	.horizontalMenu {
		>.horizontalMenu-list {
			>li {
				>.horizontal-megamenu {
					.megamenu-content {
						border: 0;
						box-shadow: 0;
					}
				}
			}
		}
	}
}
@media (min-width:992px) {
	.horizontalMenu {
		>.horizontalMenu-list {
			>li {
				>ul.sub-menu {
					opacity: 0;
					visibility: hidden;
					-o-transform-origin: 0% 0%;
					-ms-transform-origin: 0% 0%;
					-moz-transform-origin: 0% 0%;
					-webkit-transform-origin: 0% 0%;
					-o-transition: -o-transform 0.3s, opacity 0.3s;
					-ms-transition: -ms-transform 0.3s, opacity 0.3s;
					-moz-transition: -moz-transform 0.3s, opacity 0.3s;
					-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
					transform-style: preserve-3d;
					-o-transform-style: preserve-3d;
					-moz-transform-style: preserve-3d;
					-webkit-transform-style: preserve-3d;
					transform: rotateX(-75deg);
					-o-transform: rotateX(-75deg);
					-moz-transform: rotateX(-75deg);
					-webkit-transform: rotateX(-75deg);
					>li {
						>ul.sub-menu {
							opacity: 0;
							visibility: hidden;
							transform-style: preserve-3d;
							-o-transform-style: preserve-3d;
							-moz-transform-style: preserve-3d;
							-webkit-transform-style: preserve-3d;
							transform: rotateX(-75deg);
							-o-transform: rotateX(-75deg);
							-moz-transform: rotateX(-75deg);
							-webkit-transform: rotateX(-75deg);
							>li {
								>ul.sub-menu {
									opacity: 0;
									visibility: hidden;
									-o-transform-origin: 0% 0%;
									-ms-transform-origin: 0% 0%;
									-moz-transform-origin: 0% 0%;
									-webkit-transform-origin: 0% 0%;
									-o-transition: -o-transform 0.4s, opacity 0.4s;
									-ms-transition: -ms-transform 0.4s, opacity 0.4s;
									-moz-transition: -moz-transform 0.4s, opacity 0.4s;
									-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
									transform-style: preserve-3d;
									-o-transform-style: preserve-3d;
									-moz-transform-style: preserve-3d;
									-webkit-transform-style: preserve-3d;
									transform: rotateX(-75deg);
									-o-transform: rotateX(-75deg);
									-moz-transform: rotateX(-75deg);
									-webkit-transform: rotateX(-75deg);
								}
								&:hover {
									>ul.sub-menu {
										opacity: 1;
										visibility: visible;
										transform: rotateX(0deg);
										-o-transform: rotateX(0deg);
										-moz-transform: rotateX(0deg);
										-webkit-transform: rotateX(0deg);
									}
								}
							}
						}
						&:hover {
							>ul.sub-menu {
								opacity: 1;
								visibility: visible;
								-o-transform-origin: 0% 0%;
								-ms-transform-origin: 0% 0%;
								-moz-transform-origin: 0% 0%;
								-webkit-transform-origin: 0% 0%;
								-o-transition: -o-transform 0.4s, opacity 0.4s;
								-ms-transition: -ms-transform 0.4s, opacity 0.4s;
								-moz-transition: -moz-transform 0.4s, opacity 0.4s;
								-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
								transform: rotateX(0deg);
								-o-transform: rotateX(0deg);
								-moz-transform: rotateX(0deg);
								-webkit-transform: rotateX(0deg);
							}
						}
					}
				}
				&:hover {
					>ul.sub-menu {
						opacity: 1;
						visibility: visible;
						transform: rotateX(0deg);
						-o-transform: rotateX(0deg);
						-moz-transform: rotateX(0deg);
						-webkit-transform: rotateX(0deg);
					}
					>.horizontal-megamenu {
						opacity: 1;
						visibility: visible;
						transform: rotateX(0deg);
						-o-transform: rotateX(0deg);
						-moz-transform: rotateX(0deg);
						-webkit-transform: rotateX(0deg);
					}
				}
				>.horizontal-megamenu {
					opacity: 0;
					visibility: hidden;
					-o-transform-origin: 0% 0%;
					-ms-transform-origin: 0% 0%;
					-moz-transform-origin: 0% 0%;
					-webkit-transform-origin: 0% 0%;
					-o-transition: -o-transform 0.3s, opacity 0.3s;
					-ms-transition: -ms-transform 0.3s, opacity 0.3s;
					-moz-transition: -moz-transform 0.3s, opacity 0.3s;
					-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
					transform-style: preserve-3d;
					-o-transform-style: preserve-3d;
					-moz-transform-style: preserve-3d;
					-webkit-transform-style: preserve-3d;
					transform: rotateX(-75deg);
					-o-transform: rotateX(-75deg);
					-moz-transform: rotateX(-75deg);
					-webkit-transform: rotateX(-75deg);
				}
			}
		}
	}
}
