
:root {

	--primary-bg-color: #1c46c5;
    --primary-bg-hover: #2c55cf;
    --primary-bg-border: #1c46c5;
	--primary-bg-transparentcolor:rgba(28, 70, 197, 0.2);
	--primary-shadow:rgba(28, 70, 197, 0.5);
    --primary-01: rgba(28, 70, 197, 0.1);
    --primary-2: rgba(28, 70, 197, 0.2);
    --primary-3: rgba(28, 70, 197, 0.3);
    --primary-4: rgba(28, 70, 197, 0.4);
    --primary-5: rgba(28, 70, 197, 0.5);
    --primary-6: rgba(28, 70, 197, 0.6);
    --primary-7: rgba(28, 70, 197, 0.7);
    --primary-8: rgba(28, 70, 197, 0.8);
    --primary-9: rgba(28, 70, 197, 0.9);

	--secondary-color: #a72071;
	--secondary-hover: #ad347d;
	--secondary-transparent:rgba(167, 32, 113, 0.2);
	--secondary-shadow: rgba(167, 32, 113, 0.5);
    --secondary-1: rgba(167, 32, 113, 0.1);
    --secondary-2: rgba(167, 32, 113, 0.2);
    --secondary-3: rgba(167, 32, 113, 0.3);
    --secondary-4: rgba(167, 32, 113, 0.4);
    --secondary-5: rgba(167, 32, 113, 0.5);
    --secondary-6: rgba(167, 32, 113, 0.6);
    --secondary-7: rgba(167, 32, 113, 0.7);
    --secondary-8: rgba(167, 32, 113, 0.8);
    --secondary-9: rgba(167, 32, 113, 0.9);


	--dark-body: #2a2e3f;
	--dark-theme: #212332;
	--dark-theme2: rgba(0, 0, 0, 0.3);
	--dark-border: rgba(255, 255, 255, 0.1);
	--dark-color:#fff;
	--dark-shadow:rgba(36, 38, 48, 0.2);
    --float-left: left;
    --float-right: right;
}


$background:#f7faff;
$color:#29334c;
$border: #e6e6f5;
$hover:#fbfbff;

/*Color variables*/
$primary1 : var(--primary-bg-color);
$primary-hover : var(--primary-bg-hover);
$primary-border : var(--primary-bg-border);
$primary-shadow :var(--primary-bg-shadow);
$primary-transparent: var(--primary-bg-transparentcolor);
$secondary :var(--secondary-color);
$secondary-hover :var(--secondary-hover);
$secondary-shadow: var(--secondary-shadow);
$secondary-transparent: var(--secondary-transparent);

/*transparent primary and secondary*/
$primary01 : var(--primary-01);
$primary2 : var(--primary-2);
$primary3 : var(--primary-3);
$primary4 : var(--primary-4);
$primary5 : var(--primary-5);
$primary6 : var(--primary-6);
$primary7 : var(--primary-7);
$primary8 : var(--primary-8);
$primary9 : var(--primary-9);
$secondary-1: var(--secondary-1);
$secondary-2: var(--secondary-2);
$secondary-3: var(--secondary-3);
$secondary-4: var(--secondary-4);
$secondary-5: var(--secondary-5);
$secondary-6: var(--secondary-6);
$secondary-7: var(--secondary-7);
$secondary-8: var(--secondary-8);
$secondary-9: var(--secondary-9);

$info:#0ab2e6;
$success:#17d092;
$warning:#ffa012;
$danger:#fc3d50;
$blue:#467fcf;
$yellow:#ffa22b;
$teal:#136dba;
$pink:#df33df;
$purple:#604dd8;
$gray-dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;

/*white variables*/

$white-05:rgba(255, 255, 255, 0.05);
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/

$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


$dark-body: var(--dark-body);
$dark-theme: var(--dark-theme);
$dark-theme2: var(--dark-theme2);
$dark-border: var(--dark-border);
$dark-color: var(--dark-color);
$dark-shadow: var(--dark-shadow);

$float-left:var(--float-left);
$float-right:var(--float-right);

[dir="rtl"] {
  --float-right:left;
  --float-left:right;
}

$info:#0ab2e6;
$success:#17d092;
$warning:#ffa012;
$danger:#fc3d50;
$blue:#467fcf;
$yellow:#ffa22b;
$teal:#136dba;
$purple:#604dd8;
$gray-dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;
$gray:#ecf0ff;