/*------------------------------------------------------------------
[Master Stylesheet]

Project        :   Medz - Medical Directory HTML Template
Version        :   V.1
Create Date    :   07/11/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support		   :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

 
@import "variables";


/* ------ Table Of Contents  

	bootstrap-styles
	accordion
	alerts
	avatars
	badges
	breadcrumbs
	buttons
	card-styles
	dropdown-styles
	grid
	input-group
	jumbotron
	modal
	nav-styles
	pagination
	panels
	popover
	progress
	table
	tags
	thumbnails
	tooltip
	components-cols
	count-down
	custom-styles
	gallery
	highlight
	Ie-styles
	labels
	list-styles
	media
	pricing-styles
	ratings
	tabs
	timeline
	color
	dark-style
	footer
	header
	horizontal
	icons
	item-styles
	loaders
	rtl
	sidebar
	skin-modes
	calendar
	checkbox
	custom-control
	custom-range
	custom-switch
	date-picker
	form-elements
	jvector
	rangeslider
	select2
	select-group
	selectize
	boot-slider
	countdown
	fancy_fileupload
	flipclock
	horizontal
	image-tooltip
	insideElementDemo
	jquery-ui
	jquery-uislider
	jquerysctipttop
	nice-select
	owl.carousel
	pscrollbar
	select2.min
	spectrum
	admin-navbar
	charts
	chat-styles
	error-pages
	mails
	map-styles
	ribbons
	user-cards
	weather-cards
	widgets
	alignment
	background
	border
	display
	float-styles
	height
	margin
	padding
	position
	typography
	width
	
 ------ */
 


@import "custom/fonts/fonts";



/* ###### bootstrap ####### */

@import "bootstrap/bootstrap-styles";
@import "bootstrap/accordion";
@import "bootstrap/alerts";
@import "bootstrap/avatars";
@import "bootstrap/badges";
@import "bootstrap/breadcrumbs";
@import "bootstrap/buttons";
@import "bootstrap/card-styles";
@import "bootstrap/dropdown-styles";
@import "bootstrap/grid";
@import "bootstrap/input-group";
@import "bootstrap/modal";
@import "bootstrap/nav-styles";
@import "bootstrap/pagination";
@import "bootstrap/panels";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/table";
@import "bootstrap/tags";
@import "bootstrap/thumbnails";
@import "bootstrap/tooltip";

/* ###### custom ####### */

@import "custom/components-cols";
@import "custom/count-down";
@import "custom/custom-styles";
@import "custom/gallery";
@import "custom/highlight";
@import "custom/Ie-styles";
@import "custom/labels";
@import "custom/list-styles";
@import "custom/media";
@import "custom/pricing-styles";
@import "custom/ratings";
@import "custom/tabs";
@import "custom/timeline";

/* ###### layout ####### */

@import "layout/color";
@import "layout/dark-style";
@import "layout/footer";
@import "layout/header";
@import "layout/horizontal";
@import "layout/icons";
@import "layout/item-styles";
@import "layout/loaders";
@import "layout/rtl";
@import "layout/skin-modes";

/* ###### lib ####### */

@import "lib/calendar";
@import "lib/checkbox";
@import "lib/custom-control";
@import "lib/custom-range";
@import "lib/custom-switch";
@import "lib/date-picker";
@import "lib/form-elements";
@import "lib/jvector";
@import "lib/rangeslider";
@import "lib/select2";
@import "lib/select-group";
@import "lib/selectize";

/* ###### plugins ####### */

@import "plugins/boot-slider";
@import "plugins/countdown";
@import "plugins/fancy_fileupload";
@import "plugins/flipclock";
@import "plugins/horizontal";
@import "plugins/image-tooltip";
@import "plugins/insideElementDemo";
@import "plugins/jquery-ui";
@import "plugins/jquery-uislider";
@import "plugins/jquerysctipttop";
@import "plugins/nice-select";
@import "plugins/owl.carousel";
@import "plugins/pscrollbar";
@import "plugins/select2.min";
@import "plugins/spectrum";


/* ###### template ####### */
@import "template/error-pages";
@import "template/mails";
@import "template/map-styles";
@import "template/ribbons";
@import "template/user-cards";
@import "template/weather-cards";
@import "template/widgets";

/* ###### util ####### */

@import "util/alignment";
@import "util/background";
@import "util/border";
@import "util/display";
@import "util/float-styles";
@import "util/height";
@import "util/margin";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";