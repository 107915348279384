/* Interaction Cues
----------------------------------*/
/* Icons
----------------------------------*/
/* states and images */
/* Misc visuals
----------------------------------*/
/* Overlays */
/* to make room for the icon, a width needs to be set here */
/* button elements seem to need a little more width */
/* button text element */
/* no icon support for input elements, provide padding by default */
/* button icon element(s) */
/* button sets */
/* workarounds */
/* reset extra padding in Firefox, see h5bp.com/l */
/* with multiple calendars */
/* RTL support */
/* icon support */
/* left-aligned */
/* right-aligned */
/* support: IE8 - See #6727 */
/* more specificity required here to override default borders */
/* vertically center icon */
/* TR overrides */
/* Component containers
----------------------------------*/
/* Interaction Cues
----------------------------------*/
/* Icons
----------------------------------*/
/* states and images */
/* positioning */
/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
/* Misc visuals
----------------------------------*/
/* Corner radius */
/* Overlays */
.ui-helper-hidden {
	display: none;
}
.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}
.ui-helper-clearfix {
	&:before {
		content: "";
		display: table;
		border-collapse: collapse;
	}
	&:after {
		content: "";
		display: table;
		border-collapse: collapse;
		clear: both;
	}
	min-height: 0;
}
.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	inset-inline-start: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0);
}
.ui-front {
	z-index: 100;
}
.ui-state-disabled {
	cursor: default !important;
	opacity: .35;
	filter: Alpha(Opacity=35);
	background-image: none;
	.ui-icon {
		filter: Alpha(Opacity=35);
	}
}
.ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
	width: 16px;
	height: 16px;
	//background-image: url("images/ui-icons_c02669_256x240.png");
}
.ui-widget-overlay {
	position: fixed;
	top: 0;
	inset-inline-start: 0;
	width: 100%;
	height: 100%;
	background: #f7f7ba url("images/ui-bg_white-lines_85_f7f7ba_40x100.png") 50% 50% repeat;
	opacity: .8;
	filter: Alpha(Opacity=80);
}
.ui-accordion {
	.ui-accordion-header {
		display: block;
		cursor: pointer;
		position: relative;
		margin: 2px 0 0 0;
		padding: .5em .5em .5em .7em;
		min-height: 0;
		font-size: 100%;
		.ui-accordion-header-icon {
			position: absolute;
			inset-inline-start: .5em;
			top: 50%;
			margin-block-start: -8px;
		}
	}
	.ui-accordion-icons {
		padding-inline-start: 2.2em;
		.ui-accordion-icons {
			padding-inline-start: 2.2em;
		}
	}
	.ui-accordion-content {
		padding: 1em 2.2em;
		border-block-start: 0;
		overflow: auto;
	}
}
.ui-autocomplete {
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	cursor: default;
}
.ui-button {
	display: inline-block;
	position: relative;
	padding: 0;
	line-height: normal;
	margin-inline-end: .1em;
	cursor: pointer;
	vertical-align: middle;
	text-align: center;
	overflow: visible;
	text-decoration: none;
	&:link {
		text-decoration: none;
	}
	&:visited {
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
	}
	&:active {
		text-decoration: none;
	}
	.ui-button-text {
		display: block;
		line-height: normal;
	}
}
.ui-button-icon-only {
	width: 2.2em;
	.ui-button-text {
		padding: .4em;
		text-indent: -9999999px;
	}
	.ui-icon {
		position: absolute;
		top: 50%;
		margin-block-start: -8px;
		inset-inline-start: 50%;
		margin-inline-start: -8px;
	}
}
button.ui-button-icon-only {
	width: 2.4em;
}
.ui-button-icons-only {
	width: 3.4em;
	.ui-button-text {
		padding: .4em;
		text-indent: -9999999px;
	}
	.ui-icon {
		position: absolute;
		top: 50%;
		margin-block-start: -8px;
	}
	.ui-button-icon-primary {
		inset-inline-start: .5em;
	}
	.ui-button-icon-secondary {
		inset-inline-end: .5em;
	}
}
button.ui-button-icons-only {
	width: 3.7em;
}
.ui-button-text-only {
	.ui-button-text {
		padding: .4em 1em;
	}
}
.ui-button-text-icon-primary {
	.ui-button-text {
		padding: .4em 1em .4em 2.1em;
	}
	.ui-icon {
		position: absolute;
		top: 50%;
		margin-block-start: -8px;
	}
	.ui-button-icon-primary {
		inset-inline-start: .5em;
	}
}
.ui-button-text-icons {
	.ui-button-text {
		padding: .4em 1em .4em 2.1em;
		padding: .4em 2.1em .4em 1em;
		padding-inline-start: 2.1em;
		padding-inline-end: 2.1em;
	}
	.ui-icon {
		position: absolute;
		top: 50%;
		margin-block-start: -8px;
	}
	.ui-button-icon-primary {
		inset-inline-start: .5em;
	}
	.ui-button-icon-secondary {
		inset-inline-end: .5em;
	}
}
.ui-button-text-icon-secondary {
	.ui-button-text {
		padding: .4em 2.1em .4em 1em;
	}
	.ui-icon {
		position: absolute;
		top: 50%;
		margin-block-start: -8px;
	}
	.ui-button-icon-secondary {
		inset-inline-end: .5em;
	}
}
input.ui-button {
	padding: .4em 1em;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
.ui-buttonset {
	margin-inline-end: 7px;
	.ui-button {
		margin-inline-start: 0;
		margin-inline-end: -.3em;
	}
}
button.ui-button {
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}
.ui-datepicker {
	width: 17em;
	padding: .2em .2em 0;
	display: none;
	.ui-datepicker-header {
		position: relative;
		padding: .2em 0;
	}
	.ui-datepicker-prev {
		position: absolute;
		top: 2px;
		width: 1.8em;
		height: 1.8em;
		inset-inline-start: 2px;
		span {
			display: block;
			position: absolute;
			inset-inline-start: 50%;
			margin-inline-start: -8px;
			top: 50%;
			margin-block-start: -8px;
		}
	}
	.ui-datepicker-next {
		position: absolute;
		top: 2px;
		width: 1.8em;
		height: 1.8em;
		inset-inline-end: 2px;
		span {
			display: block;
			position: absolute;
			inset-inline-start: 50%;
			margin-inline-start: -8px;
			top: 50%;
			margin-block-start: -8px;
		}
	}
	.ui-datepicker-prev-hover {
		top: 1px;
		inset-inline-start: 1px;
	}
	.ui-datepicker-next-hover {
		top: 1px;
		inset-inline-end: 1px;
	}
	.ui-datepicker-title {
		margin: 0 2.3em;
		line-height: 1.8em;
		text-align: center;
		select {
			font-size: 1em;
			margin: 1px 0;
		}
	}
	select.ui-datepicker-month {
		width: 45%;
	}
	select.ui-datepicker-year {
		width: 45%;
	}
	table {
		width: 100%;
		font-size: .9em;
		border-collapse: collapse;
		margin: 0 0 .4em;
	}
	th {
		padding: .7em .3em;
		text-align: center;
		font-weight: bold;
		border: 0;
	}
	td {
		border: 0;
		padding: 1px;
		span {
			display: block;
			padding: .2em;
			text-align: right;
			text-decoration: none;
		}
		a {
			display: block;
			padding: .2em;
			text-align: right;
			text-decoration: none;
		}
	}
	.ui-datepicker-buttonpane {
		background-image: none;
		margin: .7em 0 0 0;
		padding: 0 .2em;
		border-inline-start: 0;
		border-inline-end: 0;
		border-block-end: 0;
		button {
			float: $float-right;
			margin: .5em .2em .4em;
			cursor: pointer;
			padding: .2em .6em .3em .6em;
			width: auto;
			overflow: visible;
		}
		button.ui-datepicker-current {
			float: $float-left;
		}
	}
}
.ui-datepicker.ui-datepicker-multi {
	width: auto;
}
.ui-datepicker-multi {
	.ui-datepicker-group {
		float: $float-left;
		table {
			width: 95%;
			margin: 0 auto .4em;
		}
	}
	.ui-datepicker-group-last {
		.ui-datepicker-header {
			border-inline-start-width: 0;
		}
	}
	.ui-datepicker-group-middle {
		.ui-datepicker-header {
			border-inline-start-width: 0;
		}
	}
	.ui-datepicker-buttonpane {
		clear: left;
	}
}
.ui-datepicker-multi-2 {
	.ui-datepicker-group {
		width: 50%;
	}
}
.ui-datepicker-multi-3 {
	.ui-datepicker-group {
		width: 33.3%;
	}
}
.ui-datepicker-multi-4 {
	.ui-datepicker-group {
		width: 25%;
	}
}
.ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0;
}
.ui-datepicker-rtl {
	direction: rtl;
	.ui-datepicker-prev {
		inset-inline-end: 2px;
		inset-inline-start: auto;
		&:hover {
			inset-inline-end: 1px;
			inset-inline-start: auto;
		}
	}
	.ui-datepicker-next {
		inset-inline-start: 2px;
		inset-inline-end: auto;
		&:hover {
			inset-inline-start: 1px;
			inset-inline-end: auto;
		}
	}
	.ui-datepicker-buttonpane {
		clear: right;
		button {
			float: $float-left;
		}
		button.ui-datepicker-current {
			float: $float-right;
		}
	}
	.ui-datepicker-group {
		float: $float-right;
	}
	.ui-datepicker-group-last {
		.ui-datepicker-header {
			border-inline-end-width: 0;
			border-inline-start-width: 1px;
		}
	}
	.ui-datepicker-group-middle {
		.ui-datepicker-header {
			border-inline-end-width: 0;
			border-inline-start-width: 1px;
		}
	}
}
.ui-dialog {
	overflow: hidden;
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	padding: .2em;
	outline: 0;
	.ui-dialog-titlebar {
		padding: .4em 1em;
		position: relative;
	}
	.ui-dialog-title {
		float: $float-left;
		margin: .1em 0;
		white-space: nowrap;
		width: 90%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.ui-dialog-titlebar-close {
		position: absolute;
		inset-inline-end: .3em;
		top: 50%;
		width: 20px;
		margin: -10px 0 0 0;
		padding: 1px;
		height: 20px;
	}
	.ui-dialog-content {
		position: relative;
		border: 0;
		padding: .5em 1em;
		background: none;
		overflow: auto;
	}
	.ui-dialog-buttonpane {
		text-align: start;
		border-width: 1px 0 0 0;
		background-image: none;
		margin-block-start: .5em;
		padding: .3em 1em .5em .4em;
		.ui-dialog-buttonset {
			float: $float-right;
		}
		button {
			margin: .5em .4em .5em 0;
			cursor: pointer;
		}
	}
	.ui-resizable-se {
		width: 12px;
		height: 12px;
		inset-inline-end: -5px;
		bottom: -5px;
		background-position: 16px 16px;
	}
}
.ui-draggable {
	.ui-dialog-titlebar {
		cursor: move;
	}
}
.ui-draggable-handle {
	-ms-touch-action: none;
	touch-action: none;
}
.ui-menu {
	list-style: none;
	padding: 0;
	margin: 0;
	display: block;
	outline: none;
	.ui-menu {
		position: absolute;
	}
	.ui-menu-item {
		position: relative;
		margin: 0;
		padding: 3px 1em 3px .4em;
		cursor: pointer;
		min-height: 0;
	}
	.ui-menu-divider {
		margin: 5px 0;
		height: 0;
		font-size: 0;
		line-height: 0;
		border-width: 1px 0 0 0;
	}
	.ui-state-focus {
		margin: -1px;
	}
	.ui-state-active {
		margin: -1px;
	}
	.ui-icon {
		position: absolute;
		top: 0;
		bottom: 0;
		inset-inline-start: .2em;
		margin: auto 0;
	}
	.ui-menu-icon {
		inset-inline-start: auto;
		inset-inline-end: 0;
	}
}
.ui-menu-icons {
	position: relative;
	.ui-menu-item {
		padding-inline-start: 2em;
	}
}
.ui-progressbar {
	height: 2em;
	text-align: start;
	overflow: hidden;
	.ui-progressbar-value {
		margin: -1px;
		height: 100%;
	}
	.ui-progressbar-overlay {
		height: 100%;
		filter: alpha(opacity=25);
		opacity: 0.25;
	}
}
.ui-progressbar-indeterminate {
	.ui-progressbar-value {
		background-image: none;
	}
}
.ui-resizable {
	position: relative;
}
.ui-resizable-handle {
	position: absolute;
	font-size: 0.1px;
	display: block;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-resizable-disabled {
	.ui-resizable-handle {
		display: none;
	}
}
.ui-resizable-autohide {
	.ui-resizable-handle {
		display: none;
	}
}
.ui-resizable-n {
	cursor: n-resize;
	height: 7px;
	width: 100%;
	top: -5px;
	inset-inline-start: 0;
}
.ui-resizable-s {
	cursor: s-resize;
	height: 7px;
	width: 100%;
	bottom: -5px;
	inset-inline-start: 0;
}
.ui-resizable-e {
	cursor: e-resize;
	width: 7px;
	inset-inline-end: -5px;
	top: 0;
	height: 100%;
}
.ui-resizable-w {
	cursor: w-resize;
	width: 7px;
	inset-inline-start: -5px;
	top: 0;
	height: 100%;
}
.ui-resizable-se {
	cursor: se-resize;
	width: 12px;
	height: 12px;
	inset-inline-end: 1px;
	bottom: 1px;
}
.ui-resizable-sw {
	cursor: sw-resize;
	width: 9px;
	height: 9px;
	inset-inline-start: -5px;
	bottom: -5px;
}
.ui-resizable-nw {
	cursor: nw-resize;
	width: 9px;
	height: 9px;
	inset-inline-start: -5px;
	top: -5px;
}
.ui-resizable-ne {
	cursor: ne-resize;
	width: 9px;
	height: 9px;
	inset-inline-end: -5px;
	top: -5px;
}
.ui-selectable {
	-ms-touch-action: none;
	touch-action: none;
}
.ui-selectable-helper {
	position: absolute;
	z-index: 100;
	border: 1px dotted black;
}
.ui-selectmenu-menu {
	padding: 0;
	margin: 0;
	position: absolute;
	top: 0;
	inset-inline-start: 0;
	display: none;
	.ui-menu {
		overflow: auto;
		overflow-x: hidden;
		padding-block-end: 1px;
		.ui-selectmenu-optgroup {
			font-size: 1em;
			font-weight: bold;
			line-height: 1.5;
			padding: 2px 0.4em;
			margin: 0.5em 0 0 0;
			height: auto;
			border: 0;
		}
	}
}
.ui-selectmenu-open {
	display: block;
}
.ui-selectmenu-button {
	display: inline-block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	cursor: pointer;
	span.ui-icon {
		inset-inline-end: 0.5em;
		inset-inline-start: auto;
		margin-block-start: -8px;
		position: absolute;
		top: 50%;
	}
	span.ui-selectmenu-text {
		text-align: start;
		padding: 0.4em 2.1em 0.4em 1em;
		display: block;
		line-height: 1.4;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
.ui-slider {
	position: relative;
	text-align: start;
	.ui-slider-handle {
		position: absolute;
		z-index: 2;
		width: 1.2em;
		height: 1.2em;
		cursor: default;
		-ms-touch-action: none;
		touch-action: none;
	}
	.ui-slider-range {
		position: absolute;
		z-index: 1;
		font-size: .7em;
		display: block;
		border: 0;
		background-position: 0 0;
	}
}
.ui-slider.ui-state-disabled {
	.ui-slider-handle {
		filter: inherit;
	}
	.ui-slider-range {
		filter: inherit;
	}
}
.ui-slider-horizontal {
	height: .8em;
	.ui-slider-handle {
		top: -.3em;
		margin-inline-start: -.6em;
	}
	.ui-slider-range {
		top: 0;
		height: 100%;
	}
	.ui-slider-range-min {
		inset-inline-start: 0;
	}
	.ui-slider-range-max {
		inset-inline-end: 0;
	}
}
.ui-slider-vertical {
	width: .8em;
	height: 100px;
	.ui-slider-handle {
		inset-inline-start: -.3em;
		margin-inline-start: 0;
		margin-block-end: -.6em;
	}
	.ui-slider-range {
		inset-inline-start: 0;
		width: 100%;
	}
	.ui-slider-range-min {
		bottom: 0;
	}
	.ui-slider-range-max {
		top: 0;
	}
}
.ui-sortable-handle {
	-ms-touch-action: none;
	touch-action: none;
}
.ui-spinner {
	position: relative;
	display: inline-block;
	overflow: hidden;
	padding: 0;
	vertical-align: middle;
	a.ui-spinner-button {
		border-block-start: none;
		border-block-end: none;
		border-inline-end: none;
	}
	.ui-icon {
		position: absolute;
		margin-block-start: -8px;
		top: 50%;
		inset-inline-start: 0;
	}
	.ui-icon-triangle-1-s {
		background-position: -65px -16px;
	}
}
.ui-spinner-input {
	border: none;
	background: none;
	color: inherit;
	padding: 0;
	margin: .2em 0;
	vertical-align: middle;
	margin-inline-start: .4em;
	margin-inline-end: 22px;
}
.ui-spinner-button {
	width: 16px;
	height: 50%;
	font-size: .5em;
	padding: 0;
	margin: 0;
	text-align: center;
	position: absolute;
	cursor: default;
	display: block;
	overflow: hidden;
	inset-inline-end: 0;
}
.ui-spinner-up {
	top: 0;
}
.ui-spinner-down {
	bottom: 0;
}
.ui-tabs {
	position: relative;
	padding: .2em;
	.ui-tabs-nav {
		margin: 0;
		padding: .2em .2em 0;
		li {
			list-style: none;
			float: $float-left;
			position: relative;
			top: 0;
			margin: 1px .2em 0 0;
			border-block-end-width: 0;
			padding: 0;
			white-space: nowrap;
		}
		.ui-tabs-anchor {
			float: $float-left;
			padding: .5em 1em;
			text-decoration: none;
		}
		li.ui-tabs-active {
			margin-block-end: -1px;
			padding-block-end: 1px;
			.ui-tabs-anchor {
				cursor: text;
			}
		}
		li.ui-state-disabled {
			.ui-tabs-anchor {
				cursor: text;
			}
		}
		li.ui-tabs-loading {
			.ui-tabs-anchor {
				cursor: text;
			}
		}
	}
	.ui-tabs-panel {
		display: block;
		border-width: 0;
		padding: 1em 1.4em;
		background: none;
	}
}
.ui-tabs-collapsible {
	.ui-tabs-nav {
		li.ui-tabs-active {
			.ui-tabs-anchor {
				cursor: pointer;
			}
		}
	}
}
.ui-tooltip {
	padding: 8px;
	position: absolute;
	z-index: 9999;
	max-width: 300px;
	-webkit-box-shadow: 0 0 5px #aaa;
	box-shadow: 0 0 5px #aaa;
}
body {
	.ui-tooltip {
		border-width: 2px;
	}
}
.ui-widget {
	font-family: Gill Sans,Arial,sans-serif;
	font-size: 1.2em;
	.ui-widget {
		font-size: 1em;
	}
	input {
		font-family: Gill Sans,Arial,sans-serif;
		font-size: 1em;
	}
	select {
		font-family: Gill Sans,Arial,sans-serif;
		font-size: 1em;
	}
	textarea {
		font-family: Gill Sans,Arial,sans-serif;
		font-size: 1em;
	}
	button {
		font-family: Gill Sans,Arial,sans-serif;
		font-size: 1em;
	}
}
.ui-widget-content {
	border: 1px solid #aaaaaa;
	background: #ffffff;
	color: #2c4359;
	a {
		color: #2c4359;
	}
	.ui-state-hover {
		border: 1px solid $primary1;
		background: $primary1;
		font-weight: bold;
		color: #212121;
	}
	.ui-state-focus {
		border: 1px solid $primary1;
		background: $primary1;
		font-weight: bold;
		color: #212121;
	}
	.ui-state-active {
		border: 1px solid #f1f3f8;
		background: $primary1;
		font-weight: bold;
		color: #ffffff;
	}
	.ui-state-highlight {
		border: 1px solid #b4d100;
		background: #ffff38;
		color: #363636;
		a {
			color: #363636;
		}
	}
	.ui-state-error {
		border: 1px solid #ff6b7f;
		background: #ff3853;
		color: #ffffff;
		a {
			color: #ffffff;
		}
	}
	.ui-state-error-text {
		color: #ffffff;
	}
	.ui-priority-primary {
		font-weight: bold;
	}
	.ui-priority-secondary {
		opacity: .7;
		filter: Alpha(Opacity=70);
		font-weight: normal;
	}
	.ui-state-disabled {
		opacity: .35;
		filter: Alpha(Opacity=35);
		background-image: none;
	}
	//.ui-icon {
	//	background-image: url("images/ui-icons_c02669_256x240.png");
	//}
}
.ui-state-default {
	a {
		color: #333333;
		text-decoration: none;
		&:link {
			color: #333333;
			text-decoration: none;
		}
		&:visited {
			color: #333333;
			text-decoration: none;
		}
	}
	// .ui-icon {
	// 	background-image: url("images/ui-icons_ffffff_256x240.png");
	// }
}
.ui-state-hover {
	border: 1px solid $primary1;
	background: $primary1;
	font-weight: bold;
	color: #212121;
	a {
		color: #212121;
		text-decoration: none;
		&:hover {
			color: #212121;
			text-decoration: none;
		}
		&:link {
			color: #212121;
			text-decoration: none;
		}
		&:visited {
			color: #212121;
			text-decoration: none;
		}
	}
	// .ui-icon {
	// 	background-image: url("images/ui-icons_454545_256x240.png");
	// }
}
.ui-widget-header {
	.ui-state-hover {
		border: 1px solid $primary1;
		background: $primary1;
		font-weight: bold;
		color: #212121;
	}
	.ui-state-focus {
		border: 1px solid $primary1;
		background: $primary1;
		font-weight: bold;
		color: #212121;
	}
	.ui-state-active {
		border: 1px solid #f1f3f8;
		background: $primary1;
		font-weight: bold;
		color: #ffffff;
	}
	.ui-state-highlight {
		border: 1px solid #b4d100;
		background: #ffff38;
		color: #363636;
		a {
			color: #363636;
		}
	}
	.ui-state-error {
		border: 1px solid #ff6b7f;
		background: #ff3853;
		color: #ffffff;
		a {
			color: #ffffff;
		}
	}
	.ui-state-error-text {
		color: #ffffff;
	}
	.ui-priority-primary {
		font-weight: bold;
	}
	.ui-priority-secondary {
		opacity: .7;
		filter: Alpha(Opacity=70);
		font-weight: normal;
	}
	.ui-state-disabled {
		opacity: .35;
		filter: Alpha(Opacity=35);
		background-image: none;
	}
	//.ui-icon {
	//	background-image: url("images/ui-icons_e1e463_256x240.png");
	//}
}
.ui-state-focus {
	border: 1px solid $primary1;
	background: $primary1;
	font-weight: bold;
	color: #212121;
	a {
		color: #212121;
		text-decoration: none;
		&:hover {
			color: #212121;
			text-decoration: none;
		}
		&:link {
			color: #212121;
			text-decoration: none;
		}
		&:visited {
			color: #212121;
			text-decoration: none;
		}
	}
	//.ui-icon {
	//	background-image: url("images/ui-icons_454545_256x240.png");
	//}
}
.ui-state-active {
	border: 1px solid #f1f3f8;
	background: $primary1;
	font-weight: bold;
	color: #ffffff;
	a {
		color: #ffffff;
		text-decoration: none;
		&:link {
			color: #ffffff;
			text-decoration: none;
		}
		&:visited {
			color: #ffffff;
			text-decoration: none;
		}
	}
	//.ui-icon {
	//	background-image: url("images/ui-icons_ffffff_256x240.png");
	//}
}
.ui-state-highlight {
	border: 1px solid #b4d100;
	background: #ffff38;
	color: #363636;
	a {
		color: #363636;
	}
	//.ui-icon {
	//	background-image: url("images/ui-icons_88a206_256x240.png");
	//}
}
.ui-state-error {
	border: 1px solid #ff6b7f;
	background: #ff3853;
	color: #ffffff;
	a {
		color: #ffffff;
	}
	//.ui-icon {
	//	background-image: url("images/ui-icons_ffeb33_256x240.png");
	//}
}
.ui-state-error-text {
	color: #ffffff;
	//.ui-icon {
	//	background-image: url("images/ui-icons_ffeb33_256x240.png");
	//}
}
.ui-priority-primary {
	font-weight: bold;
}
.ui-priority-secondary {
	opacity: .7;
	filter: Alpha(Opacity=70);
	font-weight: normal;
}
.ui-icon-blank {
	background-position: 16px 16px;
}
.ui-icon-carat-1-n {
	background-position: 0 0;
}
.ui-icon-carat-1-ne {
	background-position: -16px 0;
}
.ui-icon-carat-1-e {
	background-position: -32px 0;
}
.ui-icon-carat-1-se {
	background-position: -48px 0;
}
.ui-icon-carat-1-s {
	background-position: -64px 0;
}
.ui-icon-carat-1-sw {
	background-position: -80px 0;
}
.ui-icon-carat-1-w {
	background-position: -96px 0;
}
.ui-icon-carat-1-nw {
	background-position: -112px 0;
}
.ui-icon-carat-2-n-s {
	background-position: -128px 0;
}
.ui-icon-carat-2-e-w {
	background-position: -144px 0;
}
.ui-icon-triangle-1-n {
	background-position: 0 -16px;
}
.ui-icon-triangle-1-ne {
	background-position: -16px -16px;
}
.ui-icon-triangle-1-e {
	background-position: -32px -16px;
}
.ui-icon-triangle-1-se {
	background-position: -48px -16px;
}
.ui-icon-triangle-1-s {
	background-position: -64px -16px;
}
.ui-icon-triangle-1-sw {
	background-position: -80px -16px;
}
.ui-icon-triangle-1-w {
	background-position: -96px -16px;
}
.ui-icon-triangle-1-nw {
	background-position: -112px -16px;
}
.ui-icon-triangle-2-n-s {
	background-position: -128px -16px;
}
.ui-icon-triangle-2-e-w {
	background-position: -144px -16px;
}
.ui-icon-arrow-1-n {
	background-position: 0 -32px;
}
.ui-icon-arrow-1-ne {
	background-position: -16px -32px;
}
.ui-icon-arrow-1-e {
	background-position: -32px -32px;
}
.ui-icon-arrow-1-se {
	background-position: -48px -32px;
}
.ui-icon-arrow-1-s {
	background-position: -64px -32px;
}
.ui-icon-arrow-1-sw {
	background-position: -80px -32px;
}
.ui-icon-arrow-1-w {
	background-position: -96px -32px;
}
.ui-icon-arrow-1-nw {
	background-position: -112px -32px;
}
.ui-icon-arrow-2-n-s {
	background-position: -128px -32px;
}
.ui-icon-arrow-2-ne-sw {
	background-position: -144px -32px;
}
.ui-icon-arrow-2-e-w {
	background-position: -160px -32px;
}
.ui-icon-arrow-2-se-nw {
	background-position: -176px -32px;
}
.ui-icon-arrowstop-1-n {
	background-position: -192px -32px;
}
.ui-icon-arrowstop-1-e {
	background-position: -208px -32px;
}
.ui-icon-arrowstop-1-s {
	background-position: -224px -32px;
}
.ui-icon-arrowstop-1-w {
	background-position: -240px -32px;
}
.ui-icon-arrowthick-1-n {
	background-position: 0 -48px;
}
.ui-icon-arrowthick-1-ne {
	background-position: -16px -48px;
}
.ui-icon-arrowthick-1-e {
	background-position: -32px -48px;
}
.ui-icon-arrowthick-1-se {
	background-position: -48px -48px;
}
.ui-icon-arrowthick-1-s {
	background-position: -64px -48px;
}
.ui-icon-arrowthick-1-sw {
	background-position: -80px -48px;
}
.ui-icon-arrowthick-1-w {
	background-position: -96px -48px;
}
.ui-icon-arrowthick-1-nw {
	background-position: -112px -48px;
}
.ui-icon-arrowthick-2-n-s {
	background-position: -128px -48px;
}
.ui-icon-arrowthick-2-ne-sw {
	background-position: -144px -48px;
}
.ui-icon-arrowthick-2-e-w {
	background-position: -160px -48px;
}
.ui-icon-arrowthick-2-se-nw {
	background-position: -176px -48px;
}
.ui-icon-arrowthickstop-1-n {
	background-position: -192px -48px;
}
.ui-icon-arrowthickstop-1-e {
	background-position: -208px -48px;
}
.ui-icon-arrowthickstop-1-s {
	background-position: -224px -48px;
}
.ui-icon-arrowthickstop-1-w {
	background-position: -240px -48px;
}
.ui-icon-arrowreturnthick-1-w {
	background-position: 0 -64px;
}
.ui-icon-arrowreturnthick-1-n {
	background-position: -16px -64px;
}
.ui-icon-arrowreturnthick-1-e {
	background-position: -32px -64px;
}
.ui-icon-arrowreturnthick-1-s {
	background-position: -48px -64px;
}
.ui-icon-arrowreturn-1-w {
	background-position: -64px -64px;
}
.ui-icon-arrowreturn-1-n {
	background-position: -80px -64px;
}
.ui-icon-arrowreturn-1-e {
	background-position: -96px -64px;
}
.ui-icon-arrowreturn-1-s {
	background-position: -112px -64px;
}
.ui-icon-arrowrefresh-1-w {
	background-position: -128px -64px;
}
.ui-icon-arrowrefresh-1-n {
	background-position: -144px -64px;
}
.ui-icon-arrowrefresh-1-e {
	background-position: -160px -64px;
}
.ui-icon-arrowrefresh-1-s {
	background-position: -176px -64px;
}
.ui-icon-arrow-4 {
	background-position: 0 -80px;
}
.ui-icon-arrow-4-diag {
	background-position: -16px -80px;
}
.ui-icon-extlink {
	background-position: -32px -80px;
}
.ui-icon-newwin {
	background-position: -48px -80px;
}
.ui-icon-refresh {
	background-position: -64px -80px;
}
.ui-icon-shuffle {
	background-position: -80px -80px;
}
.ui-icon-transfer-e-w {
	background-position: -96px -80px;
}
.ui-icon-transferthick-e-w {
	background-position: -112px -80px;
}
.ui-icon-folder-collapsed {
	background-position: 0 -96px;
}
.ui-icon-folder-open {
	background-position: -16px -96px;
}
.ui-icon-document {
	background-position: -32px -96px;
}
.ui-icon-document-b {
	background-position: -48px -96px;
}
.ui-icon-note {
	background-position: -64px -96px;
}
.ui-icon-mail-closed {
	background-position: -80px -96px;
}
.ui-icon-mail-open {
	background-position: -96px -96px;
}
.ui-icon-suitcase {
	background-position: -112px -96px;
}
.ui-icon-comment {
	background-position: -128px -96px;
}
.ui-icon-person {
	background-position: -144px -96px;
}
.ui-icon-print {
	background-position: -160px -96px;
}
.ui-icon-trash {
	background-position: -176px -96px;
}
.ui-icon-locked {
	background-position: -192px -96px;
}
.ui-icon-unlocked {
	background-position: -208px -96px;
}
.ui-icon-bookmark {
	background-position: -224px -96px;
}
.ui-icon-tag {
	background-position: -240px -96px;
}
.ui-icon-home {
	background-position: 0 -112px;
}
.ui-icon-flag {
	background-position: -16px -112px;
}
.ui-icon-calendar {
	background-position: -32px -112px;
}
.ui-icon-cart {
	background-position: -48px -112px;
}
.ui-icon-pencil {
	background-position: -64px -112px;
}
.ui-icon-clock {
	background-position: -80px -112px;
}
.ui-icon-disk {
	background-position: -96px -112px;
}
.ui-icon-calculator {
	background-position: -112px -112px;
}
.ui-icon-zoomin {
	background-position: -128px -112px;
}
.ui-icon-zoomout {
	background-position: -144px -112px;
}
.ui-icon-search {
	background-position: -160px -112px;
}
.ui-icon-wrench {
	background-position: -176px -112px;
}
.ui-icon-gear {
	background-position: -192px -112px;
}
.ui-icon-heart {
	background-position: -208px -112px;
}
.ui-icon-star {
	background-position: -224px -112px;
}
.ui-icon-link {
	background-position: -240px -112px;
}
.ui-icon-cancel {
	background-position: 0 -128px;
}
.ui-icon-plus {
	background-position: -16px -128px;
}
.ui-icon-plusthick {
	background-position: -32px -128px;
}
.ui-icon-minus {
	background-position: -48px -128px;
}
.ui-icon-minusthick {
	background-position: -64px -128px;
}
.ui-icon-close {
	background-position: -80px -128px;
}
.ui-icon-closethick {
	background-position: -96px -128px;
}
.ui-icon-key {
	background-position: -112px -128px;
}
.ui-icon-lightbulb {
	background-position: -128px -128px;
}
.ui-icon-scissors {
	background-position: -144px -128px;
}
.ui-icon-clipboard {
	background-position: -160px -128px;
}
.ui-icon-copy {
	background-position: -176px -128px;
}
.ui-icon-contact {
	background-position: -192px -128px;
}
.ui-icon-image {
	background-position: -208px -128px;
}
.ui-icon-video {
	background-position: -224px -128px;
}
.ui-icon-script {
	background-position: -240px -128px;
}
.ui-icon-alert {
	background-position: 0 -144px;
}
.ui-icon-info {
	background-position: -16px -144px;
}
.ui-icon-notice {
	background-position: -32px -144px;
}
.ui-icon-help {
	background-position: -48px -144px;
}
.ui-icon-check {
	background-position: -64px -144px;
}
.ui-icon-bullet {
	background-position: -80px -144px;
}
.ui-icon-radio-on {
	background-position: -96px -144px;
}
.ui-icon-radio-off {
	background-position: -112px -144px;
}
.ui-icon-pin-w {
	background-position: -128px -144px;
}
.ui-icon-pin-s {
	background-position: -144px -144px;
}
.ui-icon-play {
	background-position: 0 -160px;
}
.ui-icon-pause {
	background-position: -16px -160px;
}
.ui-icon-seek-next {
	background-position: -32px -160px;
}
.ui-icon-seek-prev {
	background-position: -48px -160px;
}
.ui-icon-seek-end {
	background-position: -64px -160px;
}
.ui-icon-seek-start {
	background-position: -80px -160px;
}
.ui-icon-seek-first {
	background-position: -80px -160px;
}
.ui-icon-stop {
	background-position: -96px -160px;
}
.ui-icon-eject {
	background-position: -112px -160px;
}
.ui-icon-volume-off {
	background-position: -128px -160px;
}
.ui-icon-volume-on {
	background-position: -144px -160px;
}
.ui-icon-power {
	background-position: 0 -176px;
}
.ui-icon-signal-diag {
	background-position: -16px -176px;
}
.ui-icon-signal {
	background-position: -32px -176px;
}
.ui-icon-battery-0 {
	background-position: -48px -176px;
}
.ui-icon-battery-1 {
	background-position: -64px -176px;
}
.ui-icon-battery-2 {
	background-position: -80px -176px;
}
.ui-icon-battery-3 {
	background-position: -96px -176px;
}
.ui-icon-circle-plus {
	background-position: 0 -192px;
}
.ui-icon-circle-minus {
	background-position: -16px -192px;
}
.ui-icon-circle-close {
	background-position: -32px -192px;
}
.ui-icon-circle-triangle-e {
	background-position: -48px -192px;
}
.ui-icon-circle-triangle-s {
	background-position: -64px -192px;
}
.ui-icon-circle-triangle-w {
	background-position: -80px -192px;
}
.ui-icon-circle-triangle-n {
	background-position: -96px -192px;
}
.ui-icon-circle-arrow-e {
	background-position: -112px -192px;
}
.ui-icon-circle-arrow-s {
	background-position: -128px -192px;
}
.ui-icon-circle-arrow-w {
	background-position: -144px -192px;
}
.ui-icon-circle-arrow-n {
	background-position: -160px -192px;
}
.ui-icon-circle-zoomin {
	background-position: -176px -192px;
}
.ui-icon-circle-zoomout {
	background-position: -192px -192px;
}
.ui-icon-circle-check {
	background-position: -208px -192px;
}
.ui-icon-circlesmall-plus {
	background-position: 0 -208px;
}
.ui-icon-circlesmall-minus {
	background-position: -16px -208px;
}
.ui-icon-circlesmall-close {
	background-position: -32px -208px;
}
.ui-icon-squaresmall-plus {
	background-position: -48px -208px;
}
.ui-icon-squaresmall-minus {
	background-position: -64px -208px;
}
.ui-icon-squaresmall-close {
	background-position: -80px -208px;
}
.ui-icon-grip-dotted-vertical {
	background-position: 0 -224px;
}
.ui-icon-grip-dotted-horizontal {
	background-position: -16px -224px;
}
.ui-icon-grip-solid-vertical {
	background-position: -32px -224px;
}
.ui-icon-grip-solid-horizontal {
	background-position: -48px -224px;
}
.ui-icon-gripsmall-diagonal-se {
	background-position: -64px -224px;
}
.ui-icon-grip-diagonal-se {
	background-position: -80px -224px;
}
.ui-corner-all {
	border-start-start-radius: 2px;
	border-start-end-radius: 2px;
	border-end-start-radius: 2px;
	border-end-end-radius: 2px;
}
.ui-corner-top {
	border-start-start-radius: 2px;
	border-start-end-radius: 2px;
}
.ui-corner-left {
	border-start-start-radius: 2px;
	border-end-start-radius: 2px;
}
.ui-corner-tl {
	border-start-start-radius: 2px;
}
.ui-corner-right {
	border-start-end-radius: 2px;
	border-end-end-radius: 2px;
}
.ui-corner-tr {
	border-start-end-radius: 2px;
}
.ui-corner-bottom {
	border-end-start-radius: 2px;
	border-end-end-radius: 2px;
}
.ui-corner-bl {
	border-end-start-radius: 2px;
}
.ui-corner-br {
	border-end-end-radius: 2px;
}
.ui-widget-shadow {
	margin: 8px 0 0 8px;
	padding: 10px;
	background: #ba9217 url("images/ui-bg_flat_75_ba9217_40x100.png") 50% 50% repeat-x;
	opacity: .2;
	filter: Alpha(Opacity=20);
	border-radius: 5px;
}
