.countdown-timer-wrapper {
	border-radius: 5px;
	max-width: 500px;
	margin: 50px auto 50px auto;
	line-height: 0.50;
	h5 {
		font-size: 14px;
		letter-spacing: 0.5px;
		text-align: center;
		padding-block-start: 10px;
		text-shadow: none;
	}
	.timer {
		text-align: center;
		.timer-wrapper {
			display: inline-block;
			width: 110px;
			height: 94px;
			.time {
				font-size: 1.83rem;
				font-weight: bold;
				width: 88px;
				height: 88px;
				border-radius: 50%;
				line-height: 2;
				text-align: center;
				margin: 0 auto;
				margin-block-end: -33px;
				position: relative;
				overflow: hidden;
				background: rgb(255, 255, 255);
				z-index: -1;
				border: 5px solid rgb(255, 255, 255);
			}
			.text {
				font-size: 13px;
				color: #000;
				padding-block-start: 2px;
			}
		}
	}
}
.countdown-timer-wrapper.countdown-timer-wrapper1 {
	margin: 0px auto;
	.timer {
		.timer-wrapper {
			.time {
				background: rgba(0, 0, 0, 0.2);
				color: #ffffff;
			}
			.text {
				color: #fff;
			}
		}
	}
}
@media (mx-width:568px) {
	.countdown-timer-wrapper {
		.timer {
			.timer-wrapper {
				margin-block-start: 20px !important;
				.time {
					margin-block-start: 20px !important;
				}
			}
		}
	}
}
@media (max-width:544px) {
	.countdown-timer-wrapper {
		.timer {
			.timer-wrapper {
				.time {
					width: 70px;
					height: 70px;
					font-size: 1.50rem;
					margin-block-end: -24px;
				}
			}
		}
		border-radius: 5px;
		max-width: 300px;
		text-align: center;
	}
}
